import { Input, NavLeft } from 'framework7-react';
import { FC, useEffect, useMemo, useRef, useState } from 'react';

import { useAppContext } from '@contexts/AppContext';
import dayjs from 'dayjs';

import {
  useCityEntertainerAvailabilityQuery,
  useCurrentEntertainerAvailabilityQuery,
  useCurrentEntertainerQuery,
} from '@queries/EntertainerQueries';
import { useCityBookingOpportunitiesQuery } from '@queries/PromoterQueries';
import { useCurrentUser } from '@queries/UserQueries';

import { useAddUserBookingOpportunitiesMutation } from '@mutations/UserMutations';

import { useAnalytics } from '@hooks/useAnalytics';
import { useCurrentUserCity } from '@hooks/useCurrentUserLocation';

import { useRouter } from '@router/index';

import Button from '@components/buttons/Button';
import IconButton from '@components/buttons/IconButton';
import { ShareLinkButton } from '@components/buttons/ShareLinkButton';
import { PostCard } from '@components/cards/PostCard/PostCard';
import { DatePicker } from '@components/form/DatePicker';
import { Select } from '@components/form/Select';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import { ButtonFilter } from '@components/layout/ButtonFilter/ButtonFilter';
import Card from '@components/layout/Card/Card';
import { useDialog } from '@components/layout/Dialog/useDialog';
import { EmptyState } from '@components/layout/EmptyState/EmptyState';
import { LinkBanner } from '@components/layout/LinkBanner/LinkBanner';
import { Screen } from '@components/layout/Screen';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import { BookingOpportunityCard } from '@screens/entertainer/BookingOpportunityCard';
import { DRAG_PERFORMER_CATEGORIES } from '@screens/entertainer/CreateEntertainerProfileScreen';
import { useEntertainerFeed } from '@screens/entertainer/EntertainerRankingsScreen/CityEntertainerRatingScreen';
import { FeaturedEntertainersSection } from '@screens/entertainer/EntertainerRankingsScreen/FeaturedEntertainersSection';
import { PastEventsBadgesSection } from '@screens/entertainer/EntertainerRankingsScreen/PastEventsBadgesSection';
import { AvailabilityReferralInput } from '@screens/entertainer/ManageEntertainerProfileScreen';
import { ShareBookingOpportunityButton } from '@screens/promoter/ManagePromoterBookingOpportunityScreen/ManagePromoterBookingOpportunityScreen';

import { useFetchNextPosts } from '../../../queries/PostQueries';
import { PrimaryBookingWizardEntryPoint } from '../BookingWizardScreen/BookingWizardScreen';
import { EntertainerAvailabilityCard } from './EntertainerAvailabilityCard';

const TAB_OPTION_RECORD = {
  opportunities: 'Opportunities',
  availability: 'Availability',
  // feed: 'Feed',
};
type TabOption = keyof typeof TAB_OPTION_RECORD;

const TAB_OPTIONS = Object.entries(TAB_OPTION_RECORD).map(([value, label]) => ({ value, label }));

const GigTabContent = () => {
  const { track } = useAnalytics();
  const { navigate, back } = useRouter();
  const { entertainer, bookingOpportunities } = useCurrentEntertainerQuery();
  const { currentCity } = useCurrentUserCity();
  const [selectedDate, setSelectedDate] = useState<string>();

  return (
    <>
      <LinkBanner
        icon={
          <IconButton className="mx-auto" variant="smooth" color="neutral" size="lg">
            <FontAwesomeIcon name="search" />
          </IconButton>
        }
        title="I'm an entertainer looking for gigs"
        description="Share your availability and get discovered for upcoming opportunities."
        onClick={() => {
          navigate('/noauth/booking-wizard/entertainer', {
            state: {
              goBackOnSave: true,
            },
          });
        }}
      />
      <TitleToolbar
        text="Gig Opportunities"
        size="sm"
        action={
          <Button
            startIcon={<FontAwesomeIcon name="plus" />}
            roundness="rounded"
            variant="flat"
            onClick={() => {
              track(`Click add opportunity`);
              navigate('/noauth/booking-wizard/promoter', {
                state: {
                  goBackOnSave: true,
                },
              });
            }}
          >
            Add An Opportunity
          </Button>
        }
      />
      {entertainer ? (
        <>
          {bookingOpportunities?.length ? (
            bookingOpportunities.map((bookingOpportunity) => (
              <BookingOpportunityCard
                key={bookingOpportunity._id}
                bookingOpportunity={bookingOpportunity}
              />
            ))
          ) : (
            <EmptyState
              title="No available opportunities"
              text="Check back later for more opportunities. Use the button below to add your availability to get discovered."
              button={{
                text: 'Add my availability',
                onClick: () => {
                  track(`Click add availability (empty state)`);
                  navigate('/noauth/booking-wizard/entertainer', {
                    state: {
                      goBackOnSave: true,
                    },
                  });
                },
              }}
            />
          )}
        </>
      ) : (
        <EmptyState
          title="Gigs Unavailable"
          text="Gig opportunities are only available to entertainers. Use the button below to set up an entertainer profile."
          button={{
            text: 'Create Entertainer Profile',
            onClick: () => {
              track(`Click create entertainer profile`);
              back('/entertainer/onboarding');
            },
          }}
        />
      )}
    </>
  );
};

export const TalentTabContent: FC<{ isPastEventsSectionEnabled?: boolean; back?: string }> = ({
  isPastEventsSectionEnabled = false,
  back,
}) => {
  const { track } = useAnalytics();
  const { navigate } = useRouter();
  const { userIsLoggedIn } = useAppContext();
  const { entertainer } = useCurrentEntertainerQuery();
  const { currentCity } = useCurrentUserCity();
  const [selectedDate, setSelectedDate] = useState<string>();
  const [didSendRequest, setDidSendRequest] = useState(false);
  const [category, setCategory] = useState('');
  const { openConfirmDialog } = useDialog();

  const { cityEntertainerAvailability } = useCityEntertainerAvailabilityQuery(currentCity?._id);

  const { isAddingUserBookingOpportunities, addUserBookingOpportunitiesAsync } =
    useAddUserBookingOpportunitiesMutation();

  const selectedDateAvailability = useMemo(() => {
    if (selectedDate) {
      const entertainerIds = cityEntertainerAvailability?.availabilityByDate?.[selectedDate] ?? [];

      const matchingDateEntertainers = entertainerIds
        .filter((entertainerId) => cityEntertainerAvailability?.entertainersById?.[entertainerId])
        .map((entertainerId) => cityEntertainerAvailability?.entertainersById?.[entertainerId]);

      if (category) {
        return matchingDateEntertainers.filter((entertainer) =>
          entertainer?.details?.categories.includes(category),
        );
      }

      return matchingDateEntertainers;
    }

    return [];
  }, [selectedDate, cityEntertainerAvailability, category]);

  const requestButton = useMemo(() => {
    if (isAddingUserBookingOpportunities) {
      return {
        text: 'Requesting...',
        onClick: () => {},
      };
    }

    if (didSendRequest) {
      return {
        text: 'Request sent',
        onClick: () => {},
      };
    }

    return {
      text: 'Request availability',
      onClick: async () => {
        track(`Click Request availability`);
        openConfirmDialog({
          title: 'Request Availability',
          text: `Are you sure you want to send a request to all available entertainers for ${dayjs(
            selectedDate,
          ).format('ddd, MMM D, YYYY')}?`,
          buttonOk: 'Send',
          onConfirm: async () => {
            setDidSendRequest(true);
            await addUserBookingOpportunitiesAsync({
              cityId: currentCity._id,
              bookingOpportunites: [{ startDate: selectedDate }],
            });
            setTimeout(() => {
              setDidSendRequest(false);
            }, 4000);
          },
        });
      },
    };
  }, [isAddingUserBookingOpportunities, didSendRequest, selectedDate]);

  return (
    <>
      <LinkBanner
        icon={
          <IconButton className="mx-auto" variant="smooth" color="neutral" size="lg">
            <FontAwesomeIcon name="search" />
          </IconButton>
        }
        title="I'm a producer looking for entertainers"
        description="Find performers who fit your needs and book your next event."
        onClick={() => {
          navigate('/noauth/booking-wizard/promoter', {
            state: {
              back,
              goBackOnSave: true,
            },
          });
        }}
      />
      {isPastEventsSectionEnabled && userIsLoggedIn && (
        <PastEventsBadgesSection cityId={currentCity._id} />
      )}
      <FeaturedEntertainersSection isTrendingLooksCtaVisible cityId={currentCity._id} />
      <TitleToolbar
        text="Available Entertainers"
        size="sm"
        action={
          entertainer && (
            <Button
              startIcon={<FontAwesomeIcon name="plus" />}
              roundness="rounded"
              variant="flat"
              onClick={() => {
                track(`Click add availability`);
                navigate('/noauth/booking-wizard/entertainer', {
                  state: {
                    back,
                    goBackOnSave: true,
                  },
                });
              }}
            >
              Add Availability
            </Button>
          )
        }
      />
      <div className="d-flex gap-2 mb-3">
        <Input
          type="datepicker"
          placeholder="Select a date"
          calendarParams={{
            closeOnSelect: true,
            on: {
              change(_, value: Date[] = []) {
                const date = value[0];
                const formattedDate = dayjs(date).format('YYYY-MM-DD');
                track(`Browse availability date`, {
                  date: formattedDate,
                });
                setSelectedDate(formattedDate);
              },
            },
          }}
        />
        <Select
          value={category}
          options={[
            { value: '', label: 'All Entertainers' },
            ...DRAG_PERFORMER_CATEGORIES.map((category) => ({
              value: category,
              label: category,
            })),
          ]}
          onChange={(event) => {
            track(`Browse availability filter`, {
              filter: event.target.value,
            });
            setCategory(event.target.value);
          }}
        />
      </div>
      {selectedDate ? (
        <>
          {selectedDateAvailability?.length ? (
            selectedDateAvailability.map((entertainer) => (
              <EntertainerAvailabilityCard
                key={entertainer._id}
                date={selectedDate}
                cityId={currentCity._id}
                entertainer={entertainer}
                back={back ?? '/gigs-and-talent'}
              />
            ))
          ) : (
            <EmptyState
              title="No available entertainers"
              text="Check back later for more entertainers. Use the button below to double check that entertainers aren't available."
              button={requestButton}
            />
          )}
        </>
      ) : (
        <EmptyState title="Select a date" text="Use the filters to view available entertainers" />
      )}
    </>
  );
};

export const FeedTabContent: FC<{ postsAreReady: boolean; posts: any[] }> = ({
  postsAreReady,
  posts,
}) => {
  const { currentCity } = useCurrentUserCity();

  if (postsAreReady && !posts?.length) {
    return (
      <EmptyState
        title="Nothing trending"
        text={`Gaggl is just getting started in ${currentCity?.name}. Check back later to see the more activity.`}
      />
    );
  }

  return <>{posts?.map((post, i) => <PostCard key={post._id ?? i} post={post} />)}</>;
};

export const ReferralCard = () => {
  const [isCardVisible, setIsCardVisible] = useState(false);
  const { entertainer } = useCurrentEntertainerQuery();
  const { track } = useAnalytics();
  // const { currentEntertainerAvailabilityIsReady, currentEntertainerAvailability } =
  //   useCurrentEntertainerAvailabilityQuery(entertainer?._id);

  // const hasEnteredAvailability =
  //   !!currentEntertainerAvailability?.length || entertainer?.hasFlexibleAvailability;
  // if (currentEntertainerAvailabilityIsReady && !hasEnteredAvailability) {
  //   return
  // }

  if (!entertainer?.availabilityReferralUrl) {
    return <></>;
  }

  if (!isCardVisible) {
    return (
      <Button
        roundness="rounded"
        variant="smooth"
        color="neutral"
        startIcon={<>💰</>}
        endIcon={<FontAwesomeIcon className="ml-auto" name="chevron-down" />}
        onClick={() => {
          track('Show Referral Details');
          setIsCardVisible(true);
        }}
      >
        Earn hundreds for referrals!
      </Button>
    );
  }

  return (
    <Card>
      <Card.Body>
        <div>
          <div className="mb-2">
            <div className="mb-1 fs-6">
              <strong>Earn hundreds for referrals! 💰</strong>
            </div>
            Earn up to $5 for every entertainer you refer using your unique link! Your referrals
            help grow our community, keeping it vibrant and full of opportunities for everyone.
          </div>
          <AvailabilityReferralInput
            availabilityReferralUrl={entertainer.availabilityReferralUrl}
          />
        </div>
      </Card.Body>
    </Card>
  );
};

export const GigsAndTalentScreen: FC<{}> = () => {
  const { track } = useAnalytics();
  const { navigate, back } = useRouter();
  const [currentTab, setCurrentTab] = useState<TabOption>('opportunities');
  const { currentUser, currentUserIsReady } = useCurrentUser();
  const { entertainer } = useCurrentEntertainerQuery();
  const { currentCity } = useCurrentUserCity();
  const [selectedDate, setSelectedDate] = useState<string>();
  const { entertainerFeedPosts, entertainerFeedPostsAreReady, fetchNextEntertainerFeedPosts } =
    useEntertainerFeed({ cityId: currentCity?._id });

  return (
    <Screen
      name="Gigs & Talent Screen"
      headerProps={{
        left: <NavLeft backLink backLinkUrl={'/'} backLinkForce />,
        right: (
          <ShareLinkButton
            url="https://my.gaggl.app/noauth/booking-wizard"
            track={{ event: 'Share Booking Wizard' }}
          />
        ),
      }}
      hideToolbar
    >
      <Screen.Content
        maxWidth={425}
        // infiniteScroll={{
        //   hasReachedEnd: currentTab !== 'feed',
        //   onLoadMore: fetchNextEntertainerFeedPosts,
        // }}
      >
        {currentCity && (
          <>
            {currentUserIsReady && !currentUser?.displayName && (
              <div>
                <LinkBanner
                  showHighlight
                  icon={<FontAwesomeIcon name="comment" />}
                  title="Live Feed"
                  description={`Check out the live feed to see the latest activity in ${currentCity.name}`}
                  onClick={() => {
                    track('Click Live Feed Banner');
                    back('/home');
                  }}
                  style={{
                    background: 'linear-gradient(45deg, #1283ff 0%, #d21aff 100%)',
                  }}
                />
              </div>
            )}
            <TitleToolbar
              text={currentCity ? `${currentCity.name} Gigs & Talent` : ' '}
              size="md"
            />
            <p className="mt-0">
              Use this page to explore open gigs from producers looking for talent, check
              entertainer availability for specific dates, or submit your own details to let others
              know you’re available or seeking talent.
            </p>
            <ReferralCard />
            <ButtonFilter
              options={TAB_OPTIONS}
              onChange={(value) => {
                track(`Switch to ${value} tab`);
                setCurrentTab(value as TabOption);
              }}
              selected={currentTab}
            />
            {currentTab === 'opportunities' && <GigTabContent />}
            {currentTab === 'availability' && <TalentTabContent />}
            {/* {currentTab === 'feed' && (
              <FeedTabContent
                postsAreReady={entertainerFeedPostsAreReady}
                posts={entertainerFeedPosts}
              />
            )} */}
          </>
        )}
      </Screen.Content>
    </Screen>
  );
};
