import { FC, useEffect, useState } from 'react';
import { useSwipeable } from 'react-swipeable';

import { motion } from 'framer-motion';

import { ToastNotification } from '@api/handleResponse';

import { useCurrentEntertainerQuery } from '@queries/EntertainerQueries';
import { useToastNotification } from '@queries/UserQueries';

import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import Card from '@components/layout/Card/Card';

import { UserScoreNotification } from '../UserScoreNotification/UserScoreNotification';
import './UserToastNotification.scss';

const UnwrappedUserToastNotification: FC<{
  toastNotification: ToastNotification;
}> = ({ toastNotification }) => {
  const [toastNotificationIsVisible, setToastNotificationIsVisible] = useState(false);
  const [prevId, setPrevId] = useState<string>();

  const swipeHandlers = useSwipeable({
    onSwipedUp: () => {
      setToastNotificationIsVisible(false);
    },
  });

  useEffect(() => {
    if (prevId !== toastNotification.id) {
      setToastNotificationIsVisible(true);
      setPrevId(toastNotification.id);
      setTimeout(() => {
        setToastNotificationIsVisible(false);
      }, 4000);
    }
  }, [toastNotification]);

  return (
    <motion.div
      id="user-toast-notifcation"
      initial={{ top: -200 }}
      animate={toastNotificationIsVisible ? { top: 0 } : undefined}
      exit={{ top: -200 }}
    >
      <div {...swipeHandlers}>
        <Card>
          <Card.Body>
            <div className="d-flex gap-3">
              <div
                className="user-toast-notifcation_icon"
                style={{ color: toastNotification.icon.color }}
              >
                <motion.div
                  initial={{ scale: 1 }}
                  animate={{ scale: 1.2 }}
                  transition={{ repeat: Infinity, repeatType: 'reverse', duration: 0.5 }}
                >
                  <FontAwesomeIcon name={toastNotification.icon.name} />
                </motion.div>
              </div>
              <div className="user-toast-notifcation_content">
                <h3>
                  <strong>{toastNotification.title}</strong>
                </h3>
                <p>{toastNotification.content}</p>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    </motion.div>
  );
};

export const UserToastNotification = () => {
  const { entertainerIsReady, entertainer } = useCurrentEntertainerQuery();
  const { toastNotification } = useToastNotification();

  if (entertainerIsReady && !entertainer && !toastNotification) {
    return <UserScoreNotification />;
  }

  if (!toastNotification) {
    return <></>;
  }

  return <UnwrappedUserToastNotification toastNotification={toastNotification} />;
};
