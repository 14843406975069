import { Input as F7Input } from 'framework7-react';
import { FC, useEffect, useState } from 'react';
import { Form, Spinner } from 'react-bootstrap';

import * as dayjs from 'dayjs';
import { sortBy, truncate } from 'lodash';

import { useTopEntertainersQuery } from '@queries/EntertainerQueries';
import { useEventQuery, useEvents } from '@queries/EventQueries';
import { useSeries } from '@queries/TagQueries';
import { useCurrentUser } from '@queries/UserQueries';
import { useCityVenuesQuery } from '@queries/VenueQueries';

import {
  UpdateEventPayload,
  useMarkDuplicateEventMutation,
  useUpdateEventMutation,
} from '@mutations/EventMutations';

import { useLocation, useParams } from '@router/index';

import { mapStringToParagraphs } from '@utilities/mapStringToParagraphs';

import Button from '@components/buttons/Button';
import IconButton from '@components/buttons/IconButton';
import { DatePicker, useDatePickerState } from '@components/form/DatePicker';
import { FormLabel } from '@components/form/FormLabel';
import { ImageUploadField } from '@components/form/ImageUploadField';
import { Input } from '@components/form/Input';
import { Select } from '@components/form/Select';
import { Toggle } from '@components/form/Toggle';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import { AsyncImage } from '@components/layout/AsyncImg';
import Card from '@components/layout/Card/Card';
import { List } from '@components/layout/List/List';
import { Popup } from '@components/layout/Popup/Popup';
import { Screen } from '@components/layout/Screen/Screen';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';
import { RenderTable } from '@components/table/RenderTable';

import { RecurringScheduleField } from './CreateEditEventScreen/CreateEditEventForm';
import { MultiSelectField } from './entertainer/components/CitiesField';

const EventCleanupPopupContent: FC<{ eventId: string; isCleanupScreen?: boolean }> = ({
  eventId,
  isCleanupScreen,
}) => {
  const { isFetched: seriesIsReady, series = [] } = useSeries();
  const { eventIsReady, event = {}, refetchEvent } = useEventQuery(eventId);
  const [updatedEventProperties, setUpdatedEventProperties] = useState<UpdateEventPayload>({});
  const { dateTime: startDateTime, handleUpdateDateTime: handleUpdateStartDateTime } =
    useDatePickerState({
      date: event?.startDate,
      time: event?.startTime,
    });

  const { dateTime: endDateTime, handleUpdateDateTime: handleUpdateEndDateTime } =
    useDatePickerState({
      date: event?.endDate,
      time: event?.endTime,
    });

  console.log(endDateTime, updatedEventProperties);

  const { isSubmitting: isUpdatingEvent, updateEventAsync } = useUpdateEventMutation({
    eventId,
  });

  const handleUpdateEvent = async () => {
    const { venue: updatedVenue, ...rest } = updatedEventProperties;
    const payload: UpdateEventPayload = {
      ...rest,
    };

    if (updatedVenue) {
      if (updatedVenue._id === 'custom') {
        payload.venue = { title: updatedVenue.title };
      } else {
        payload.venue = { _id: updatedVenue._id };
      }
    }

    await updateEventAsync(payload);

    refetchEvent();
  };
  const { cityVenuesIsReady, cityVenues = [] } = useCityVenuesQuery(event?.cityId);
  const { topEntertainersAreReady, topEntertainers = [] } = useTopEntertainersQuery(
    event?.cityId,
    'dragPerformer',
    true,
  );

  if (!eventIsReady) {
    return <Spinner />;
  }

  return (
    <div>
      <TitleToolbar className="mb-0" text="Edit Event" size="md" />
      <List
        className="mt-0 mb-0"
        ignoreScreenContentPadding
        showDividers={false}
        listItems={[
          {
            title: 'Is Active',
            endIcon: (
              <Toggle
                isChecked={updatedEventProperties.isActive ?? event?.isActive}
                onChange={(updatedValue) =>
                  setUpdatedEventProperties((prevValue) => ({
                    ...prevValue,
                    isActive: updatedValue,
                  }))
                }
              />
            ),
          },
        ]}
      />
      <div className="grid gap-2">
        <Input
          placeholder="Title"
          defaultValue={event.title}
          onChange={(e) =>
            setUpdatedEventProperties((prevValue) => ({
              ...prevValue,
              title: e.target.value,
            }))
          }
        />

        <div>
          {/* <FormLabel text="Start *" /> */}
          {isCleanupScreen ? (
            <F7Input
              type="datepicker"
              placeholder="Select a start date"
              readonly
              calendarParams={{
                value: startDateTime ? [dayjs(startDateTime).toDate()] : [],
                timePicker: true,
                formatValue: (values = []) => {
                  const value = values[0];
                  return value ? dayjs(values[0]).format('M/D/YY @ h:mma') : undefined;
                },
                on: {
                  change(_, value: Date[] = []) {
                    const updatedDate = value[0];
                    if (updatedDate) {
                      const { date, time } = handleUpdateEndDateTime(updatedDate);
                      setUpdatedEventProperties((prevValue) => ({
                        ...prevValue,
                        startDate: date,
                        startTime: time,
                      }));
                    }
                  },
                },
              }}
            />
          ) : (
            <DatePicker
              name="startDateTime"
              showTimeSelect
              value={startDateTime}
              onChange={(updatedDate) => {
                const { date, time } = handleUpdateStartDateTime(updatedDate);
                setUpdatedEventProperties((prevValue) => ({
                  ...prevValue,
                  startDate: date,
                  startTime: time,
                }));
              }}
            />
          )}
        </div>

        <RecurringScheduleField
          eventProperties={{
            ...event,
            ...updatedEventProperties,
          }}
          onUpdateEventProperties={(updatedProperties) => {
            setUpdatedEventProperties((prevValue) => ({
              ...prevValue,
              ...updatedProperties,
            }));
          }}
        />

        <div>
          {/* <FormLabel text="End *" /> */}
          {isCleanupScreen ? (
            <F7Input
              type="datepicker"
              placeholder="Select an end date"
              readonly
              calendarParams={{
                value: endDateTime ? [dayjs(endDateTime).toDate()] : [],
                timePicker: true,
                formatValue: (values = []) => {
                  const value = values[0];
                  console.log({ endDateTime, values, value });
                  return value ? dayjs(values[0]).format('M/D/YY @ h:mma') : undefined;
                },
                on: {
                  change(_, value: Date[] = []) {
                    const updatedDate = value[0];
                    console.log({ updatedDate });
                    if (updatedDate) {
                      const { date, time } = handleUpdateEndDateTime(updatedDate);
                      setUpdatedEventProperties((prevValue) => ({
                        ...prevValue,
                        endDate: date,
                        endTime: time,
                      }));
                    }
                  },
                },
              }}
            />
          ) : (
            <DatePicker
              name="endDateTime"
              showTimeSelect
              value={endDateTime}
              minDate={startDateTime}
              onChange={(updatedDate) => {
                const { date, time } = handleUpdateEndDateTime(updatedDate);
                setUpdatedEventProperties((prevValue) => ({
                  ...prevValue,
                  endDate: date,
                  endTime: time,
                }));
              }}
            />
          )}
        </div>

        {cityVenuesIsReady && (
          <>
            <Select
              defaultValue={event.venue._id}
              options={[
                { value: 'custom', label: 'Custom' },
                ...cityVenues.map((venue) => ({
                  value: venue._id,
                  label: venue.title,
                })),
              ]}
              onChange={(e) =>
                setUpdatedEventProperties((prevValue) => ({
                  ...prevValue,
                  venue: { _id: e.target.value },
                }))
              }
            />
            {updatedEventProperties.venue?._id === 'custom' && (
              <Input
                placeholder="Custom Venue"
                onChange={(e) =>
                  setUpdatedEventProperties((prevValue) => ({
                    ...prevValue,
                    venue: { _id: 'custom', title: e.target.value },
                  }))
                }
              />
            )}
          </>
        )}

        {topEntertainersAreReady && (
          <MultiSelectField
            allowSearch
            defaultValue={event.entertainers?.map(({ _id }) => _id) ?? []}
            options={sortBy(
              topEntertainers.map(({ _id, title }) => ({
                value: _id,
                label: title,
              })),
              'label',
            )}
            onChange={(updatedEntertainerIds) => {
              setUpdatedEventProperties((prevValue) => ({
                ...prevValue,
                entertainerIds: updatedEntertainerIds,
              }));
            }}
          />
        )}

        {seriesIsReady && (
          <MultiSelectField
            allowSearch
            defaultValue={event.series?.map(({ _id }) => _id) ?? []}
            options={sortBy(
              series.map(({ _id, title }) => ({
                value: _id,
                label: title,
              })),
              'label',
            )}
            onChange={(updatedSeriesIds) => {
              setUpdatedEventProperties((prevValue) => ({
                ...prevValue,
                tagIds: updatedSeriesIds,
              }));
            }}
          />
        )}

        <Button roundness="rounded" disabled={isUpdatingEvent} onClick={handleUpdateEvent}>
          Save
        </Button>
      </div>
    </div>
  );
};

export const EventCleanupPopup: FC<{
  isVisible: boolean;
  onClose: () => void;
  eventId: string;
}> = ({ eventId, isVisible, onClose }) => {
  return (
    <Popup isVisible={isVisible && !!eventId} onClose={onClose}>
      <Popup.Content>
        <div className="pb-4">{eventId && <EventCleanupPopupContent eventId={eventId} />}</div>
      </Popup.Content>
    </Popup>
  );
};

interface EventCleanupScreenProps {
  back?: string;
}

export const EventCleanupScreen: FC<EventCleanupScreenProps> = ({ back = '/events' }) => {
  const { eventId } = useParams<{ eventId: string }>();
  const { eventIsReady, event, refetchEvent } = useEventQuery(eventId);
  const { currentUser, currentUserIsReady } = useCurrentUser();
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const today = dayjs();
  const oneWeekBeforeEvent = dayjs(event?.startDate).subtract(1, 'week');
  const startDate = oneWeekBeforeEvent.isBefore(today) ? today : oneWeekBeforeEvent;
  const { events = [], refetchEvents } = useEvents({
    cityId: event?.cityId,
    startDate: startDate.format('YYYY-MM-DD'),
    endDate: dayjs(event?.startDate).add(1, 'week').format('YYYY-MM-DD'),
  });

  const [updatedEventProperties, setUpdatedEventProperties] = useState<UpdateEventPayload>({});

  const { isSubmitting: isUpdatingEvent, updateEventAsync } = useUpdateEventMutation({
    eventId,
  });

  const handleUpdateEvent = async () => {
    await updateEventAsync(updatedEventProperties);
    refetchEvent();
  };

  const { markDuplicateEventAsync } = useMarkDuplicateEventMutation({
    eventId,
  });

  const handleRemoveDuplicateEvent = async (duplicateEventId: string) => {
    await markDuplicateEventAsync({ duplicateEventId });
    refetchEvents();
  };

  const headCells = [
    {
      id: 'title',
      label: 'Title',
      TableBodyCell: ({ row }) => <td>{row.title}</td>,
    },
    // {
    //   id: 'description',
    //   label: 'Description',
    //   TableBodyCell: ({ row }) => (
    //     <td className="text-break">
    //       {truncate(row.excerpt ?? row.description, {
    //         length: 50,
    //         omission: '...',
    //       })}
    //     </td>
    //   ),
    // },
    { id: 'timeRange', label: 'Date', TableBodyCell: ({ row }) => <td>{row.timeRange}</td> },
    {
      id: 'action',
      label: '',
      TableBodyCell: ({ row }) => (
        <td>
          <IconButton color="danger" onClick={() => handleRemoveDuplicateEvent(row._id)}>
            <FontAwesomeIcon name="trash" />
          </IconButton>
        </td>
      ),
    },
  ];

  const rows = events
    .filter((event) => event._id !== eventId)
    .map((event) => ({
      key: event._id,
      ...event,
    }));

  const handleChangeImage = (updatedEventImage: string | ArrayBuffer) => {
    setUpdatedEventProperties((prevValue) => ({
      ...prevValue,
      eventImage: updatedEventImage,
    }));
  };

  return (
    <Screen
      name="Event Cleanup Screen"
      headerProps={{ back: { to: back }, title: event?.venueName }}
    >
      <Screen.Content>
        {currentUserIsReady && currentUser?.isSuperAdmin ? (
          <>
            {eventIsReady && event ? (
              <>
                <Card>
                  <div className="d-grid gap-2">
                    <ImageUploadField
                      placeholder="Add an event image..."
                      defaultValue={event?.img}
                      onChange={handleChangeImage}
                    />
                    <div className="px-3">
                      <Button
                        roundness="rounded"
                        variant="smooth"
                        color="neutral"
                        disabled={!updatedEventProperties?.eventImage}
                        onClick={handleUpdateEvent}
                      >
                        Save Image
                      </Button>
                    </div>
                  </div>
                  <Card.Header title={event.title} subtitle={event.timeRange} />
                  {event.excerpt && event.excerpt !== event.description && (
                    <Card.Body>{mapStringToParagraphs(event.excerpt)}</Card.Body>
                  )}
                </Card>
                {event.description && mapStringToParagraphs(event.description)}

                <EventCleanupPopupContent isCleanupScreen eventId={eventId} />

                <TitleToolbar text="Events" size="md" />
                <RenderTable headCells={headCells} rows={rows} />
              </>
            ) : (
              <div className="d-flex justify-content-center">
                <Spinner />
              </div>
            )}
          </>
        ) : (
          <div className="d-flex justify-content-center">Unauthorized</div>
        )}
      </Screen.Content>
    </Screen>
  );
};
