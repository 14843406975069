import { useMutation, useQueryClient } from '@tanstack/react-query';

import { omitBy } from 'lodash';

import API from '@api/API';

import {
  BasicRating,
  useCritiqueQuestionsQuery,
  useCurrentUserGagglRankQuery,
  useSuggestedCritiquesQuery,
} from '@queries/CritiqueQueries';
import { EntertainerRank, useTopEntertainersQuery } from '@queries/EntertainerQueries';

export interface CritiqueDocumentMutationPayload {
  primaryCritique?: BasicRating;
  rating?: number;
  like?: string[];
  dislike?: string[];
  neutral?: string[];
  comment?: string;
  meta?: Record<string, any>;
}
export const useCritiqueDocumentMutation = () => {
  const queryClient = useQueryClient();

  const { isPending, mutateAsync } = useMutation({
    mutationFn: ({
      documentType,
      documentId,
      ...payload
    }: CritiqueDocumentMutationPayload & {
      documentType: 'promoters' | 'venues' | 'entertainers' | 'events';
      documentId: string;
    }) => {
      return API.post(`/v1/critiques/${documentType}/${documentId}`, payload);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: useSuggestedCritiquesQuery.queryKey() });
      queryClient.invalidateQueries({ queryKey: useCurrentUserGagglRankQuery.queryKey() });
    },
  });

  return {
    isSubmitting: isPending,
    critiqueDocumentAsync: mutateAsync,
  };
};

export interface AnswerCritiqueQuestionMutationPayload {
  answer: string;
  documentId?: string;
}
export const useAnswerCritiqueQuestionMutation = () => {
  const queryClient = useQueryClient();

  const { isPending, mutateAsync } = useMutation({
    mutationFn: ({
      critiqueQuestionId,
      ...payload
    }: AnswerCritiqueQuestionMutationPayload & { critiqueQuestionId: string }) => {
      return API.post(`/v1/critiques/quick-picks/${critiqueQuestionId}`, payload);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: useCritiqueQuestionsQuery.queryKey() });
      queryClient.invalidateQueries({ queryKey: useCurrentUserGagglRankQuery.queryKey() });
    },
  });

  return {
    isSubmitting: isPending,
    answerCritiqueQuestionAsync: mutateAsync,
  };
};

export const useTopEntertainersOptimisticUpdate = (
  cityId: string,
  type: string,
  userIsLoggedIn: boolean,
) => {
  const queryClient = useQueryClient();

  const handleTopEntertainersOptimisticUpdate = (
    entertainerId: string,
    payload: Partial<CritiqueDocumentMutationPayload>,
  ) => {
    const queryKey = useTopEntertainersQuery.queryKey(cityId, type, userIsLoggedIn);

    const currentData = queryClient.getQueryData<{
      total: number;
      entertainers: EntertainerRank[];
    }>(queryKey);

    if (currentData) {
      queryClient.setQueryData(queryKey, {
        ...currentData,
        entertainers: currentData.entertainers.map((entertainer) => {
          if (entertainer._id === entertainerId) {
            const { primaryCritique, like, dislike, neutral } = payload;

            return {
              ...entertainer,
              primaryRating: primaryCritique || entertainer.primaryRating,
              like: like
                ? like.map((label) => ({
                    label,
                  }))
                : entertainer.like,
              dislike: dislike
                ? dislike.map((label) => ({
                    label,
                  }))
                : entertainer.dislike,
              neutral: neutral
                ? neutral.map((label) => ({
                    label,
                  }))
                : entertainer.neutral,
            };
          }

          return entertainer;
        }),
      });
    }
  };

  return { handleTopEntertainersOptimisticUpdate };
};

export const useAnswerRecommendationQuestionMutation = () => {
  // const queryClient = useQueryClient();

  const { isPending, mutateAsync } = useMutation({
    mutationFn: ({
      questionSlug,
      ...payload
    }: {
      questionSlug: string;
      entertainerIds: string[];
    }) => {
      return API.post(`/v1/recommendation-questions/${questionSlug}/entertainer-answer`, payload);
    },
    // onSettled: () => {
    //   queryClient.invalidateQueries({ queryKey: useSuggestedCritiquesQuery.queryKey() });
    //   queryClient.invalidateQueries({ queryKey: useCurrentUserGagglRankQuery.queryKey() });
    // },
  });

  return {
    isAnsweringRecommendationQuestion: isPending,
    answerRecommendationQuestionAsync: mutateAsync,
  };
};
