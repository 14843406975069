import { FC } from 'react';

import { ChipRadioOptions } from '@components/chips/ChipRadioOptions';

import { HorizontalScroll } from '../HorizontalScroll/HorizontalScroll';

interface ChipTitleFilterProps<Value> {
  value: Value;
  options: { value: Value; label: string }[];
  onChange: (value: Value) => void;
}

export const ChipTitleFilter = <Value extends string>({
  value,
  options,
  onChange,
}: ChipTitleFilterProps<Value>) => {
  return (
    <HorizontalScroll
      items={[<ChipRadioOptions options={options} value={value} onChange={onChange} />]}
    />
  );
};
