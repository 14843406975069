import { FC, PropsWithChildren, ReactElement } from 'react';
import { Button as BootstrapButton, ButtonProps as BootstrapButtonProps } from 'react-bootstrap';

import { useAnalytics } from '@hooks/useAnalytics';

import './Button.scss';

interface ButtonProps extends Omit<PropsWithChildren<BootstrapButtonProps>, 'size'> {
  variant?: 'default' | 'flat' | 'outlined' | 'smooth';
  roundness?: 'rounded' | 'pill' | 'circle';
  color?: 'primary' | 'secondary' | 'neutral' | 'danger' | 'success' | 'default';
  size?: 'sm' | 'md' | 'lg';
  track?: {
    event: string;
    properties?: {
      [key: string]: any;
    };
  };
  noWrap?: boolean;
  startIcon?: ReactElement;
  endIcon?: ReactElement;
  showHighlight?: boolean;
  // disabled: boolean;
}
const Button: FC<ButtonProps> = ({
  className = '',
  variant = 'default',
  roundness = 'pill',
  color = 'primary',
  size = 'md',
  startIcon,
  endIcon,
  noWrap,
  children,
  track: trackProperties,
  onClick,
  showHighlight,
  ...rest
}) => {
  const classNamesAppended = `btn ${className} btn-variant-${variant} btn-roundness-${roundness} btn-color-${color} btn-size-${size} ${noWrap ? 'text-nowrap' : ''} ${showHighlight ? 'show-highlight' : ''}`;
  const { track } = useAnalytics();

  const handleClick = (e) => {
    if (trackProperties) {
      track(trackProperties.event, trackProperties.properties);
    }

    onClick?.(e);
  };

  return (
    <BootstrapButton className={classNamesAppended} {...rest} onClick={handleClick}>
      <span className="d-flex align-items-center justify-content-center">
        {startIcon && <div className="btn_start-icon">{startIcon}</div>}
        <span>{children}</span>
        {endIcon && <div className="btn_end-icon">{endIcon}</div>}
      </span>
    </BootstrapButton>
  );
};

export default Button;
