import { FC, ReactNode } from 'react';
import LazyLoadComponent from 'react-lazyload';

interface LazyLoadProps {
  offset?: number;
  children: ReactNode;
  [key: string]: any;
}

export const LazyLoad: FC<LazyLoadProps> = ({ offset = 300, ...props }) => (
  <LazyLoadComponent offset={offset} {...props} />
);
