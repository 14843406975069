import { FC, useEffect, useMemo, useState } from 'react';

import { SplashNotification, useSplashNotifications } from '@queries/UserQueries';

import { useMarkSplashNotificationAsReadMutation } from '@mutations/UserMutations';

import { useAnalytics } from '@hooks/useAnalytics';
import { usePWA } from '@hooks/usePWA';

import { useLocation, useNavigate, useParams } from '@router/index';

import { isValidObjectId } from '@utilities/isValidObjectId';
import { mapStringToParagraphs } from '@utilities/mapStringToParagraphs';

import Button from '@components/buttons/Button';
import { Popup } from '@components/layout/Popup/Popup';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

export const SplashNotificationPopup: FC<{ staticPath: string; dynamicPath: string }> = ({
  staticPath,
  dynamicPath,
}) => {
  const { splashNotifications = [] } = useSplashNotifications();
  const [readRecord, setReadRecord] = useState<Record<string, boolean>>({});
  const navigate = useNavigate();
  const { track } = useAnalytics();
  const { pathname } = useLocation();

  const { canInstallPWA, promptPWAInstall } = usePWA();

  const splashNotification = useMemo<SplashNotification | undefined>(() => {
    const currentSplashNotification = splashNotifications
      .filter((notification) => !readRecord[notification._id])
      .find(
        (notification) =>
          notification.displayPath === '*' ||
          notification.displayPath === staticPath ||
          notification.displayPath === dynamicPath,
      );

    const pwaNotificationId = 'pwa-install';
    if (
      !currentSplashNotification &&
      canInstallPWA &&
      staticPath === '/' &&
      !readRecord[pwaNotificationId]
    ) {
      return {
        _id: pwaNotificationId,
        title: 'Install Gaggl on your device',
        displayPath: '/',
        imageUrl: '',
        content:
          'Add Gaggl to your home screen for the best experience. You can also enable notifications to stay updated on new events and messages.',
        button: {
          text: 'Install',
          to: '',
          action: promptPWAInstall,
        },
      };
    }

    return currentSplashNotification;
  }, [splashNotifications, readRecord, staticPath, dynamicPath]);

  const { markSplashNotificationAsReadAsync } = useMarkSplashNotificationAsReadMutation();
  useEffect(() => {
    if (isValidObjectId(splashNotification?._id)) {
      track('Splash Notification Displayed', splashNotification);
      markSplashNotificationAsReadAsync({ notificationId: splashNotification._id });
    }
  }, [splashNotification?._id]);

  const handleClose = () => {
    if (splashNotification) {
      setReadRecord((prev) => ({ ...prev, [splashNotification._id]: true }));
    }
  };

  return (
    <Popup isVisible={!!splashNotification?._id} onClose={handleClose}>
      <Popup.Content>
        {splashNotification && (
          <>
            {splashNotification.imageUrl && (
              <div>
                <img
                  src={splashNotification.imageUrl}
                  style={{ display: 'block', height: 140, margin: '0 auto' }}
                />
              </div>
            )}
            <TitleToolbar text={splashNotification.title} size="md" />
            {mapStringToParagraphs(splashNotification.content, { collapseTopMargin: true })}
            {splashNotification.button && (
              <Button
                roundness="rounded"
                onClick={() => {
                  track('Splash Notification Button Clicked', splashNotification);
                  handleClose();
                  if (splashNotification.button?.to) {
                    navigate(splashNotification.button.to);
                  }
                  splashNotification.button?.action?.();
                }}
              >
                {splashNotification.button.text}
              </Button>
            )}
          </>
        )}
      </Popup.Content>
    </Popup>
  );
};
