import { useMutation, useQueryClient } from '@tanstack/react-query';

import API from '@api/API';

import { useSeriesEntertainersQuery } from '@queries/TagQueries';

interface UpdateSeriesEntertainersMutationPayload {
  entertainerIds: string[];
}
export const useUpdateSeriesEntertainersMutation = (tagId: string) => {
  const queryClient = useQueryClient();

  const { isPending, mutateAsync } = useMutation({
    mutationFn: (payload: UpdateSeriesEntertainersMutationPayload) => {
      return API.post(`/v1/series/${tagId}/entertainers`, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: useSeriesEntertainersQuery.queryKey(tagId) });
    },
  });

  return {
    isUpdatingSeriesEntertainers: isPending,
    updateSeriesEntertainersAsync: mutateAsync,
  };
};
