import { f7ready } from 'framework7-react';
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';

import * as dayjs from 'dayjs';
import { Calendar } from 'framework7/types';

import './InlineDatePicker.scss';

export const InlineDatePicker: FC<{
  minDate?: string;
  value: Record<string, boolean>;
  onChange: Dispatch<SetStateAction<Record<string, boolean>>>; // (updatedValues: Record<string, boolean>) => void;
  onMonthYearChange?: (year: number, month: number) => void;
  events?: Calendar.DateRange;
}> = ({ minDate, value = {}, onChange, onMonthYearChange, events }) => {
  const divRef = useRef<HTMLDivElement>(null);
  const calendarRef = useRef<Calendar.Calendar>(undefined);
  const didClickRef = useRef(false);

  useEffect(() => {
    if (!didClickRef.current && calendarRef.current) {
      const updatedValues = Object.entries(value)
        .filter(([, isSelected]) => isSelected)
        .map(([key]) => dayjs(key).toDate());
      calendarRef.current.setValue(updatedValues);
    }

    didClickRef.current = false;
  }, [value]);

  useEffect(() => {
    if (divRef.current && !calendarRef.current) {
      f7ready((f7) => {
        calendarRef.current = f7.calendar.create({
          containerEl: divRef.current,
          value: Object.entries(value)
            .filter(([, isSelected]) => isSelected)
            .map(([key]) => dayjs(key).toDate()),
          multiple: true,
          minDate: minDate ? dayjs(minDate).toDate() : undefined,
          events,
          firstDay: 0,
          on: {
            monthYearChangeStart: (calendar) => {
              onMonthYearChange?.(calendar.currentYear, calendar.currentMonth);
            },
            dayClick: (calendar, dayEl, year, month, day) => {
              didClickRef.current = true;
              onChange((prevValue) => {
                const date = dayjs(`${year}-${month + 1}-${day}`).format('YYYY-MM-DD');

                const updatedSelected = {
                  ...prevValue,
                  [date]: !prevValue[date],
                };

                return updatedSelected;
              });
            },
          },
        });
      });
    }
  });

  return <div ref={divRef} />;
};
