import * as Sentry from '@sentry/react';
import { useEffect, useMemo, useRef } from 'react';

import { useDialog } from '@components/layout/Dialog/useDialog';

import { useAnalytics } from './useAnalytics';
import { useDevice } from './useDevice';

export const isInStandaloneMode = (): boolean => {
  try {
    return (
      window.matchMedia('(display-mode: standalone)').matches ||
      (window.navigator as any)?.standalone ||
      document.referrer.includes('android-app://')
    );
  } catch (e) {
    Sentry.captureException('Error checking if PWA is in standalone mode');
  }

  return false;
};

let deferredPrompt;
window.addEventListener('beforeinstallprompt', (e) => {
  try {
    e.preventDefault();
    deferredPrompt = e;
  } catch {
    Sentry.captureException('Error adding beforeinstallprompt event listener');
  }
});

export const usePWA = () => {
  const { isWeb } = useDevice();
  const { openAlertDialog } = useDialog();
  const { track } = useAnalytics();
  const isPromptedRef = useRef(false);

  const canInstallPWA = useMemo(() => {
    try {
      return !!deferredPrompt && isWeb && !isInStandaloneMode();
    } catch {
      Sentry.captureException('Error checking if PWA can be installed');
    }

    return false;
  }, []);

  const promptPWAInstall = async () => {
    try {
      track('PWA Install Prompted');
      const result = await deferredPrompt.prompt();
      const outcome: 'accepted' | 'dismissed' = result.outcome;
      if (outcome === 'accepted') {
        track('PWA Installed');
        openAlertDialog({
          title: 'App Installed',
          text: 'Go to your home screen and log in before you forget!',
        });
      }
    } catch (e) {
      Sentry.captureException('Error prompting PWA install');
    }
  };

  return { canInstallPWA, promptPWAInstall };
};
