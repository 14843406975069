import * as Sentry from '@sentry/react';
import { FC, Fragment, useEffect, useState } from 'react';

import API from '@api/API';

import { Entertainer, useFetchCurrentEntertainer } from '@queries/EntertainerQueries';

import { useAnalytics } from '@hooks/useAnalytics';
import { useCitiesQuery, useCurrentUserCity } from '@hooks/useCurrentUserLocation';

import { useNavigate } from '@router/index';

import { sanitizeInstagramUsername } from '@utilities/sanitizeInstagramUsername';

import Button from '@components/buttons/Button';
import { FormLabel } from '@components/form/FormLabel';
import { Input } from '@components/form/Input';
import { Popup } from '@components/layout/Popup/Popup';
import { Screen } from '@components/layout/Screen/Screen';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import { useAuthGate } from '@screens/LogInScreen/AuthGateContext';

import { CreateEditEntertainerProfileForm } from '../CreateEntertainerProfileScreen';

export const useOnboardEntertainer = ({
  startTimer = true,
  defaultEntertainer,
  onSubmitInstagram,
  onSkipInstagram,
  hideSkip = false,
}: {
  startTimer?: boolean;
  defaultEntertainer?: Entertainer;
  onSubmitInstagram: () => void;
  onSkipInstagram: () => void;
  hideSkip?: boolean;
}) => {
  const [countdown, setCountdown] = useState(10);
  const [unsanitizedInstagramUsername, setInstagramUsername] = useState(
    defaultEntertainer?.contact?.instagram,
  );
  const [entertainer, setEntertainer] = useState<Entertainer>(defaultEntertainer);
  const { track } = useAnalytics();
  const { createAuthGateHandler } = useAuthGate();

  useEffect(() => {
    if (startTimer) {
      setCountdown(10);

      let interval = setInterval(() => {
        setCountdown((prev) => {
          if (prev === 0) {
            clearInterval(interval);
          }

          return Math.max(0, prev - 1);
        });
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [startTimer]);

  const handleCheckInstagram = async () => {
    const instagramUsername = sanitizeInstagramUsername(unsanitizedInstagramUsername ?? '');
    if (instagramUsername) {
      track('Instagram Submitted', { instagramUsername });

      createAuthGateHandler(async () => {
        const { entertainer: fetchedEntertainer } = await API.get<{ entertainer: Entertainer }>(
          `/v1/entertainer/instagram/${instagramUsername}`,
        );

        if (fetchedEntertainer) {
          setEntertainer(fetchedEntertainer);
        }
        onSubmitInstagram();
      });
    }
  };

  const handleSkip = () => {
    track('Instagram Onboarding Skipped', { countdown });
    createAuthGateHandler(async () => {
      onSkipInstagram();
    });
  };

  const skipText = countdown === 0 ? 'Skip' : `Skip (${countdown})`;
  const hasDefaultInstagram = !!defaultEntertainer?.contact?.instagram;

  const onboardingElement = (
    <>
      <p>To get started, please enter your Instagram username below.</p>
      <div>
        <FormLabel text="Instagram Username *" />
        <Input
          type="text"
          name="instagram"
          placeholder="e.g. your.ig.handle"
          readOnly={!!defaultEntertainer?.contact?.instagram}
          defaultValue={defaultEntertainer?.contact?.instagram}
          onChange={(e) => setInstagramUsername(e.target.value)}
        />
      </div>
      <Button
        className="mt-2"
        roundness="rounded"
        disabled={!unsanitizedInstagramUsername}
        onClick={handleCheckInstagram}
      >
        Continue with Instagram
      </Button>
      {!hasDefaultInstagram && !hideSkip && (
        <Button
          className="mt-2"
          roundness="rounded"
          color="neutral"
          variant="outlined"
          disabled={countdown !== 0}
          onClick={handleSkip}
        >
          {skipText}
        </Button>
      )}
    </>
  );

  return { entertainer, onboardingElement };
};

export const OnboardEntertainerScreen: FC<{
  back?: string;
  entertainer?: Entertainer;
  onCompletePath?: string;
}> = ({ back, entertainer: defaultEntertainer, onCompletePath = '' }) => {
  const [welcomeMessageIsVisible, setWelcomeMessageIsVisible] = useState(true);
  const fetchCurrentEntertainer = useFetchCurrentEntertainer();
  const { currentCity } = useCurrentUserCity();
  const { citiesAreReady, cities } = useCitiesQuery();
  const navigate = useNavigate();

  const handleSave = async () => {
    await fetchCurrentEntertainer();
    navigate(onCompletePath ?? '/manage/entertainer');
  };

  const { entertainer, onboardingElement } = useOnboardEntertainer({
    defaultEntertainer,
    onSubmitInstagram: () => setWelcomeMessageIsVisible(false),
    onSkipInstagram: () => setWelcomeMessageIsVisible(false),
  });

  return (
    <Screen
      name="Onboard Entertainer Screen"
      disableLocationPermissionGate
      headerProps={{
        title: 'Entertainer Sign Up',
        back: back ? { to: back } : undefined,
      }}
      hideToolbar
    >
      <Screen.Content maxWidth={425}>
        {welcomeMessageIsVisible ? (
          <>
            <TitleToolbar text="Elevate Your Entertainment Career" size="md" />
            <p>
              Welcome to Gaggl, the ultimate platform designed exclusively for queer entertainers
              like you. By signing up, you&apos;ll gain access to a world of gig opportunities from
              event organizers who are looking for talented performers in the LGBTQ community. Not
              only can you discover new gigs, but you can also showcase your events and easily
              manage booking requests with our user-friendly interface.
            </p>
            <p>
              Whether you&apos;re a drag performer, musician, DJ, comedian, or go-go, Gaggl is here
              to help you connect, grow, and shine in the entertainment industry. Join our community
              today and take the first step towards your next big opportunity!
            </p>
            {onboardingElement}
          </>
        ) : (
          <>
            {citiesAreReady && (
              <CreateEditEntertainerProfileForm
                hideContactFields
                mode={onCompletePath?.includes('gigs-and-talent') ? 'minimal' : undefined}
                entertainer={entertainer}
                currentCity={currentCity}
                cities={cities.filter(({ eventsAreEnabled }) => eventsAreEnabled)}
                onSave={handleSave}
              />
            )}
          </>
        )}
      </Screen.Content>
    </Screen>
  );
};

export const OnboardEntertainerPopup: FC<{ isVisible: boolean; onClose: () => void }> = ({
  isVisible,
  onClose,
}) => {
  const [welcomeMessageIsVisible, setWelcomeMessageIsVisible] = useState(true);
  const fetchCurrentEntertainer = useFetchCurrentEntertainer();
  const { currentCity } = useCurrentUserCity();
  const { citiesAreReady, cities } = useCitiesQuery();

  const handleSave = async () => {
    try {
      await fetchCurrentEntertainer();
    } catch (e) {
      Sentry.captureException(e);
    }
    onClose();
  };

  const { entertainer, onboardingElement } = useOnboardEntertainer({
    startTimer: isVisible,
    onSubmitInstagram: () => setWelcomeMessageIsVisible(false),
    onSkipInstagram: () => setWelcomeMessageIsVisible(false),
    hideSkip: true,
  });

  return (
    <Popup isVisible={isVisible} onClose={onClose} height="full">
      <Popup.Content>
        {welcomeMessageIsVisible ? (
          <Fragment>
            <TitleToolbar text="Elevate Your Entertainment Career" size="md" />
            <p>
              Welcome to Gaggl, the ultimate platform designed exclusively for queer entertainers
              like you. By signing up, you&apos;ll gain access to a world of gig opportunities from
              event organizers who are looking for talented performers in the LGBTQ community. Not
              only can you discover new gigs, but you can also showcase your events and easily
              manage booking requests with our user-friendly interface.
            </p>
            <p>
              Whether you&apos;re a drag performer, musician, DJ, comedian, or go-go, Gaggl is here
              to help you connect, grow, and shine in the entertainment industry. Join our community
              today and take the first step towards your next big opportunity!
            </p>
            {onboardingElement}
          </Fragment>
        ) : (
          <Fragment>
            {citiesAreReady && (
              <CreateEditEntertainerProfileForm
                hideContactFields
                entertainer={entertainer}
                currentCity={currentCity}
                cities={cities.filter(({ eventsAreEnabled }) => eventsAreEnabled)}
                onSave={handleSave}
              />
            )}
          </Fragment>
        )}
      </Popup.Content>
    </Popup>
  );
};
