import * as Sentry from '@sentry/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import ReactOneSignal from 'react-onesignal';

import OneSignal from 'onesignal-cordova-plugin';

import { useCurrentUser } from '@queries/UserQueries';

import { useRouter } from '@router/index';

import { isEnv } from '@utilities/isEnv';

import { useAnalytics } from './useAnalytics';
import { useDevice } from './useDevice';
import { isInStandaloneMode } from './usePWA';

const { ONESIGNAL_APP_ID = '0cd7eddd-5b97-40f3-b6f2-7a2e5598ac48' } = process.env;

const ONESIGNAL_ENABLED = true;
const ONESIGNAL_WEB_ENABLED = true;

const usePushNotificationsEnabled = ({
  isEnabled = true,
}: { isEnabled?: boolean } | undefined): { pushNotificationsEnabled: boolean } => {
  const { isFetched, data: pushNotificationsEnabled } = useQuery({
    queryKey: ['current-user', 'pushNotificationPermissions'],
    queryFn: () => usePushNotificationsEnabled.queryFn(),
    enabled: isEnabled,
  });

  return {
    pushNotificationsEnabled,
  };
};
usePushNotificationsEnabled.queryKey = () => ['user', 'pushNotificationPermissions'];
usePushNotificationsEnabled.queryFn = () => OneSignal.Notifications.getPermissionAsync();

const useRequestPushNotificationPermission = () => {
  const queryClient = useQueryClient();

  const requestPushNotificationPermissionAsync = async () => {
    const accepted = await OneSignal.Notifications.requestPermission(true);
    queryClient.setQueryData(usePushNotificationsEnabled.queryKey(), accepted);
    return accepted;
  };

  return { requestPushNotificationPermissionAsync };
};

export const useOneSignal = () => {
  const { track } = useAnalytics();
  const { navigate } = useRouter();
  const { isNativePlatform } = useDevice();
  const { currentUser } = useCurrentUser();
  const [shouldInit, setShouldInit] = useState(false);

  const { requestPushNotificationPermissionAsync } = useRequestPushNotificationPermission();

  const initMobilePushNotifications = () => {
    // Remove this method to stop OneSignal Debugging
    // OneSignal.Debug.setLogLevel(6);

    // Replace YOUR_ONESIGNAL_APP_ID with your OneSignal App ID
    OneSignal.initialize(ONESIGNAL_APP_ID);

    OneSignal.Notifications.addEventListener('click', async (e) => {
      try {
        const notifcation = await e.notification;
        const additionalData: {
          to?: string;
          back?: string;
          [key: string]: any;
        } = notifcation.additionalData;

        track('Notification Clicked', additionalData);

        if (additionalData?.to) {
          navigate(
            additionalData.to,
            additionalData?.back ? { state: { back: additionalData.back } } : undefined,
          );
        }
      } catch (e) {
        Sentry.captureException(e);
      }
    });
  };

  const initWebPushNotifications = async () => {
    if (ONESIGNAL_WEB_ENABLED && isInStandaloneMode()) {
      await ReactOneSignal.init({
        appId: ONESIGNAL_APP_ID,
        allowLocalhostAsSecureOrigin: isEnv('dev'),
        serviceWorkerParam: { scope: '/onesignal' },
        promptOptions: {
          slidedown: {
            prompts: [
              {
                type: 'push',
                autoPrompt: false,
                text: {
                  actionMessage:
                    "We'd like to show you notifications for the latest events and updates.",
                  acceptButton: 'Allow',
                  cancelButton: 'No thanks',
                },
              },
            ],
          },
        },
      });
    }
  };

  const initOneSignal = async () => {
    try {
      if (shouldInit && !isEnv('dev') && ONESIGNAL_ENABLED) {
        if (isNativePlatform()) {
          initMobilePushNotifications();
        } else {
          await initWebPushNotifications();
        }
      }
    } catch (e) {}

    return shouldInit;
  };

  const { data: didInit } = useQuery({
    queryKey: ['oneSignal', shouldInit],
    queryFn: initOneSignal,
    enabled: shouldInit,
  });

  const queryClient = useQueryClient();
  const pushNotificationsEnabled = queryClient.getQueryData<boolean>(
    usePushNotificationsEnabled.queryKey(),
  );

  const loginOneSignal = useCallback(async () => {
    try {
      if (didInit && currentUser?._id) {
        if (isNativePlatform()) {
          OneSignal.login(currentUser._id);
          await requestPushNotificationPermissionAsync();
        } else {
          if (ONESIGNAL_WEB_ENABLED) {
            console.log('Prompting OneSignal');
            await ReactOneSignal.Slidedown.promptPush();
            console.log('Logging in OneSignal');
            await ReactOneSignal.login(currentUser._id);
            console.log('OneSignal login', currentUser._id);
          }
        }
      }
    } catch (e) {
      console.error('loginOneSignal', e);
    }
  }, [didInit, currentUser?._id]);

  useEffect(() => {
    loginOneSignal();
  }, [loginOneSignal]);

  return { initOneSignal: () => setShouldInit(didInit ? false : true), pushNotificationsEnabled };
};
