import { FC } from 'react';
import { Spinner } from 'react-bootstrap';

import { useNearbyVenuesQuery } from '@queries/index';

import { useCurrentUserCity } from '@hooks/useCurrentUserLocation';

import { useNavigate } from '@router/index';

import Button from '@components/buttons/Button';
import { VenueCard } from '@components/cards/VenueCard';
import { Screen } from '@components/layout/Screen/Screen';

import { VenueRankingsContent } from '@screens/CommunityScreen/VenueRankingsContent';

const VENUE_REQUEST_ENABLED = true;

const DeprecatedContent = () => {
  const { venuesAreReady, venues } = useNearbyVenuesQuery();
  const navigate = useNavigate();

  return venuesAreReady ? (
    <div>
      {venues.map((venue) => (
        <VenueCard
          key={venue.title}
          venue={venue}
          onClick={() => navigate(`/venues/${venue._id}`)}
        />
      ))}
    </div>
  ) : (
    <div className="d-flex justify-content-center">
      <Spinner />
    </div>
  );
};

export const VenuesScreen: FC<{}> = () => {
  const navigate = useNavigate();
  const { currentCity } = useCurrentUserCity();

  const onClickRequest = () => {
    navigate(`/venues/request`);
  };

  return (
    <Screen
      name="Venues Screen"
      headerProps={{
        back: { to: '/discover' },
        right: VENUE_REQUEST_ENABLED && (
          <Button disabled={false} variant="flat" onClick={onClickRequest}>
            Request
          </Button>
        ),
      }}
    >
      <Screen.Content maxWidth={425}>
        <VenueRankingsContent city={currentCity} back={`/venues`} />
      </Screen.Content>
    </Screen>
  );
};
