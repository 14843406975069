import { FC, Fragment, ReactNode, useState } from 'react';

import { useAppContext } from '@contexts/AppContext';
import { map } from 'cypress/types/bluebird';
import pluralize from 'pluralize';
import { Navigation, Pagination } from 'swiper';

import { useCurrentEntertainerQuery } from '@queries/EntertainerQueries';
import { FeedEvent } from '@queries/EventQueries';

import { useRespondToEntertainerEventRequestMutation } from '@mutations/EntertainerMutations';

import { useAnalytics } from '@hooks/useAnalytics';

import { useNavigate } from '@router/index';

import { findFirstAlphaCharacter } from '@utilities/findFirstAlphaCharacter';
import { mapStringToParagraphs } from '@utilities/mapStringToParagraphs';
import { openExternalUrl } from '@utilities/openExternalUrl';

import Button from '@components/buttons/Button';
import EventCard from '@components/cards/EventCard';
import Card from '@components/layout/Card/Card';
import { useDialog } from '@components/layout/Dialog/useDialog';
import { EmptyState } from '@components/layout/EmptyState/EmptyState';
import { ErrorBoundary } from '@components/layout/ErrorBoundary';
import { LetterAvatar } from '@components/layout/LetterAvatar/LetterAvatar';
import { Screen } from '@components/layout/Screen';
import { Swiper } from '@components/layout/Swiper/Swiper';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import { useAuthGate } from '@screens/LogInScreen/AuthGateContext';

const EventRequestCard: FC<{ event: FeedEvent }> = ({ event }) => {
  const headerTitle = event.promoters?.[0]?.title ?? event.venue?.title;

  return (
    <Card>
      <div className="reviewed-header-wrapper">
        <div className="reviewed-header">
          <span>Tagged Event</span>
          <Card.Header
            avatar={<LetterAvatar letter={findFirstAlphaCharacter(headerTitle)} />}
            title={headerTitle}
          />
        </div>
      </div>
      <EventCard hideRsvp showPromoter={false} event={event} onClick={() => {}} hideActions />
      <Card.Body className="pt-0">{mapStringToParagraphs(event.excerpt)}</Card.Body>
    </Card>
  );
};

const UnorderedList = ({ bullet, items }: { bullet: string; items: ReactNode[] }) => (
  <ul className="px-0 d-grid gap-1" style={{ listStyle: 'none' }}>
    {items.map((item, index) => (
      <li key={index} className="d-flex">
        <div style={{ flexShrink: 0, textAlign: 'center', display: 'block', width: 24 }}>
          {bullet}
        </div>
        <div>{item}</div>
      </li>
    ))}
  </ul>
);

const Divider = () => <hr style={{ margin: '48px 0 36px' }} />;

export const DesignPartnerScreen = () => {
  const { track } = useAnalytics();
  const navigate = useNavigate();
  const { createAuthGateHandler } = useAuthGate();
  const { userIsLoggedIn } = useAppContext();
  const { entertainer } = useCurrentEntertainerQuery();
  const [isEntertainerOnboardingPopupVisible, setIsEntertainerOnboardingPopupVisible] =
    useState(false);
  const [isEditPopupVisible, setIsEditPopupVisible] = useState(false);

  const [profileExampleIsVisible, setProfileExampleIsVisible] = useState(false);
  const [storyExampleIsVisible, setStoryExampleIsVisible] = useState(false);

  const handleEnter = () => {
    track('Click Enter');
    createAuthGateHandler(() => {});
  };

  const handleGetStarted = () => {
    const PHONE_NUMBER = `+17207342336`;
    const MESSAGE = 'PARTNER';
    const GET_STARTED_LINK = `sms:${PHONE_NUMBER}?&body=${MESSAGE}`;

    openExternalUrl(GET_STARTED_LINK);
  };

  return (
    <Screen
      name="Referral Screen"
      headerProps={{
        back: { to: '/' },
        title: <Screen.GagglTitle />,
      }}
      hideToolbar
      disableLocationPermissionGate
      bottomToolbar={
        <Screen.BottomToolbar maxWidth={425}>
          <Button onClick={handleGetStarted} roundness="rounded">
            Get Started!
          </Button>
        </Screen.BottomToolbar>
      }
    >
      <Screen.Content maxWidth={425}>
        {/* <Card>
          <img src={headerImage} alt="logo" />
        </Card> */}

        <h1 className="mb-0">Partner with Gaggl!</h1>
        <h3 className="mt-0">Help Us Build Something Game-Changing for Drag Entertainers</h3>

        <p>
          We’re looking for <strong>drag entertainers</strong> to help us shape the future of{' '}
          <strong>Gaggl</strong>—a tool designed to support and strengthen your community.
        </p>

        <p>
          We originally built an app, but we realized something important:{' '}
          <strong>we don’t know the best ways to truly help you.</strong> Instead of assuming what
          you need, we want to ask you directly.
        </p>

        <p>
          That’s where you come in! We’re launching a partnership program to get{' '}
          <strong>your input, your ideas, and your feedback</strong>—and we’ll{' '}
          <strong>pay you</strong> for it.
        </p>

        <Divider />

        <h2>How It Works</h2>

        <UnorderedList
          bullet={'✨'}
          items={[
            <>
              <strong>Step 1</strong>: Text <strong>PARTNER</strong> to{' '}
              <strong>720.734.2336</strong>
            </>,
            <>
              <strong>Step 2</strong>: Opt-in to our partnership program
            </>,
            <>
              <strong>Step 3</strong>: We’ll send you questions via text about the drag community
            </>,
            <>
              <strong>Step 4</strong>: Answer them, and we’ll send you <strong>cash tips</strong>
            </>,
          ]}
        />

        <p>That’s it! Quick, easy, and rewarding.</p>

        <Divider />

        <h2>What We Want to Learn from You</h2>

        <p>
          We’re focusing on <strong>the real questions that matter</strong> in your community:
        </p>

        <UnorderedList
          bullet={'💡'}
          items={[
            <>
              <strong>Are any producers looking for entertainers?</strong>
            </>,
            <>
              <strong>Who are the go-to makeup artists?</strong>
            </>,
            <>
              <strong>Where can performers find costume designers?</strong>
            </>,
            <>
              <strong>What are the biggest challenges drag artists face?</strong>
            </>,
          ]}
        />

        <p>
          This is just the beginning—our questions will <strong>grow and evolve</strong> based on
          your feedback.
        </p>

        <Divider />

        <h2>Why Join?</h2>

        <UnorderedList
          bullet={'🔹'}
          items={[
            <>
              <strong>Your voice matters.</strong> You’ll be shaping a tool designed to help YOU.
            </>,
            <>
              <strong>Get paid for your insights.</strong> Your knowledge is valuable, and we’ll
              compensate you for sharing it.
            </>,
            <>
              <strong>Make your community stronger.</strong> Help create a resource that answers the
              questions performers <strong>always</strong> ask.
            </>,
          ]}
        />

        <p>
          We want to build something <strong>by and for</strong> the drag community.{' '}
          <strong>Help us make it happen!</strong>
        </p>

        <UnorderedList
          bullet={'📲'}
          items={[
            <>
              <strong>Text PARTNER to 720.734.2336 to get started</strong> or click the button
              below!
            </>,
          ]}
        />
      </Screen.Content>
    </Screen>
  );
};
