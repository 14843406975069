import { Component, FC, useEffect } from 'react';

import { useAnalytics } from '@hooks/useAnalytics';

import { EmptyState } from './EmptyState/EmptyState';

const ErrorState: FC<{ error?: any; errorInfo?: any }> = ({ error, errorInfo }) => {
  const { track } = useAnalytics();

  useEffect(() => {
    track('Caught Error', {
      error,
      errorInfo,
    });
  }, []);

  const handleClick = () => {
    window.location.reload();
  };

  return (
    <div className="p-3">
      <EmptyState
        title="Uh oh!"
        text="Something went wrong displaying this screen"
        button={{ text: 'Reload', onClick: handleClick }}
      />
    </div>
  );
};

export class ErrorBoundary extends Component<
  any,
  { hasError?: boolean; error?: any; errorInfo?: any }
> {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
  }

  render() {
    const { hasError, ...rest } = this.state;
    if (hasError) {
      return <ErrorState {...rest} />;
    }

    return this.props.children;
  }
}
