import EmojiPicker, { Theme } from 'emoji-picker-react';
import { Fab } from 'framework7-react';
import { FC, useEffect, useLayoutEffect, useMemo, useState } from 'react';

import { filter } from 'cypress/types/bluebird';
import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import { sum } from 'lodash';
import pluralize from 'pluralize';
import { TALENT_CONNECTOR_ENABLED } from 'src/constants/FeatureFlags';

import {
  useCityEntertainerAvailabilityQuery,
  useCurrentEntertainerQuery,
} from '@queries/EntertainerQueries';
import { FeedPost, useFeedPostsQuery, useFetchNextPosts } from '@queries/PostQueries';
import {
  useCityBookingOpportunitiesQuery,
  useCurrentPromoterQuery,
} from '@queries/PromoterQueries';
import { useUserConversationsQuery } from '@queries/UserQueries';

import {
  useCreateNewPostMutation,
  useReactToPostMutation,
  useRespondToPostMutation,
} from '@mutations/PostMutations';

import { useTipEntertainer } from '@hooks/EntertainerHooks/useTipEntertainer';
import { useAnalytics } from '@hooks/useAnalytics';
import { useCurrentUserCity } from '@hooks/useCurrentUserLocation';

import { useQueryParams, useRouter } from '@router/index';

import { createScreenComponent } from '@utilities/createScreenComponent';
import { findFirstAlphaCharacter } from '@utilities/findFirstAlphaCharacter';
import { mapStringToParagraphs } from '@utilities/mapStringToParagraphs';

import Button from '@components/buttons/Button';
import IconButton from '@components/buttons/IconButton';
import { PostCard, PostCardHeaderWrapper } from '@components/cards/PostCard/PostCard';
import { Chip } from '@components/chips/Chip';
import { ChipRadioOptions } from '@components/chips/ChipRadioOptions';
import { Textarea } from '@components/form/Textarea';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import { ButtonFilter } from '@components/layout/ButtonFilter/ButtonFilter';
import Card from '@components/layout/Card/Card';
import { EmptyState } from '@components/layout/EmptyState/EmptyState';
import { HorizontalScroll } from '@components/layout/HorizontalScroll/HorizontalScroll';
import { LazyLoad } from '@components/layout/LazyLoad';
import { LetterAvatar } from '@components/layout/LetterAvatar/LetterAvatar';
import { LinkBanner } from '@components/layout/LinkBanner/LinkBanner';
import { Popup } from '@components/layout/Popup/Popup';
import { Screen } from '@components/layout/Screen';
import { Skeleton } from '@components/layout/Skeleton/Skeleton';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import { UserGagglRankingCard } from '@screens/HomeScreen/HomeScreen';
import { PrimaryBookingWizardEntryPoint } from '@screens/booking/BookingWizardScreen/BookingWizardScreen';
import { TalentTabContent } from '@screens/booking/GigsAndTalentScreen/GigsAndTalentScreen';
import { EntertainerRankingsContent } from '@screens/entertainer/EntertainerRankingsScreen/EntertainerRankingsScreen';
import { useRecentEventsWithEntertainers } from '@screens/entertainer/EntertainerRankingsScreen/PastEventsBadgesSection';
import { FeaturedPhotoManagementSection } from '@screens/entertainer/components/FeaturedPhotoManagementSection';

import { FeaturedKing } from '../entertainer/EntertainerDetailsScreen/assets/FeaturedKing';
import inclusiveResistImage from '../entertainer/EntertainerDetailsScreen/assets/inclusive-resist.png';
import { BadgeSection } from './BadgeSection';
import { BookingSection } from './BookingSection';
import { CommentPopup } from './CommentPopup';
import './CommunityScreen.scss';
import { CritiqueQuestionCard } from './CritiqueQuestionCard';
import { PostPopup } from './PostPopup';
import { PromoterRankingsContent } from './PromoterRankingsContent';
import { RatingActivityCard } from './RatingActivityCard';
import { VenueRankingsContent } from './VenueRankingsContent';

const TOPICS_ENABLED = true;

const TAB_OPTION_RECORD = {
  feed: 'Feed',
  venues: 'Bars',
  promoters: 'Promoters',
};
type TabOption = keyof typeof TAB_OPTION_RECORD;

const TAB_OPTIONS = Object.entries(TAB_OPTION_RECORD).map(([value, label]) => ({ value, label }));

const ConversationsIcon = () => {
  const { navigate } = useRouter();
  const { userConversations } = useUserConversationsQuery();
  const { entertainer } = useCurrentEntertainerQuery();
  const { promoter } = useCurrentPromoterQuery();

  const hasConversations = Object.values(userConversations).some(
    (conversations) => conversations.length,
  );

  const conversationsPath = useMemo(() => {
    if (entertainer) {
      return '/conversations/entertainer';
    }

    if (promoter) {
      return '/conversations/promoter';
    }

    return '/conversations/user';
  }, [entertainer, promoter]);

  if (!hasConversations) {
    return <></>;
  }

  const hasUnread = Object.values(userConversations).some((conversations) =>
    conversations.some(({ isUnread }) => isUnread),
  );

  return (
    <IconButton
      color="neutral"
      variant="smooth"
      badge={hasUnread}
      onClick={() => {
        navigate(conversationsPath, {
          state: { back: '/' },
        });
      }}
    >
      <FontAwesomeIcon name="paper-plane" />
    </IconButton>
  );
};

const TRENDING_TOPICS = {
  all: { label: 'All', filterFn: () => true },
  featuredLooks: {
    label: 'Featured Looks',
    filterFn: (post: any) => post.type === 'entertainerMedia',
  },
  critiqueQuestions: {
    label: 'Quick Critiques',
    filterFn: (post: any) => post.type === 'critiqueQuestion',
  },
  events: {
    label: 'Events',
    filterFn: (post: any) => post.type === 'eventInterest',
  },
};

export const NonEntertainerCommunityScreen = createScreenComponent<{ tab?: TabOption }>(
  ({ tab, f7route }) => {
    const { track } = useAnalytics();
    const { navigate } = useRouter();
    const [createNewPostPopupIsVisible, setCreateNewPostPopupIsVisible] = useState(false);
    const { posts: allUnfilteredPosts, postsAreReady, refetchPosts } = useFeedPostsQuery();
    const [currentPost, setCurrentPost] = useState<FeedPost>();
    const { currentCity } = useCurrentUserCity();

    const [currentTab, setCurrentTab] = useState<TabOption>(tab ?? 'feed');
    const [newPostText, setNewPostText] = useState<string>();
    const { isSubmitting: isCreatingNewPost, createNewPostAsync } = useCreateNewPostMutation();

    const { entertainer } = useCurrentEntertainerQuery();
    const { pastEventsWithEntertainersAreReady, pastEventsWithEntertainers } =
      useRecentEventsWithEntertainers(currentCity?._id);

    const handleCreateNewPost = async () => {
      setCreateNewPostPopupIsVisible(false);
      await createNewPostAsync({ text: newPostText });
      refetchPosts();
    };

    const handleCardClick = (post: FeedPost) => () => {
      setCurrentPost(post);
    };

    const [selectedTopicFilter, setSelectedTopicFilter] = useState(f7route?.query?.topic ?? 'all');
    const [postPage, setPostPage] = useState(1);
    const [additionalPosts, setAdditionalPosts] = useState<FeedPost[]>([]);
    const allFilteredPosts = useMemo(() => {
      const { filterFn = () => true } = TRENDING_TOPICS[selectedTopicFilter] ?? {};
      return [...(allUnfilteredPosts ?? []), ...additionalPosts].filter(filterFn);
    }, [allUnfilteredPosts, additionalPosts, selectedTopicFilter]);
    const posts = useMemo(() => {
      if (selectedTopicFilter === 'all') {
        return allFilteredPosts?.slice(0, postPage * 10);
      }

      return allFilteredPosts;
    }, [allFilteredPosts, postPage]);

    useEffect(() => {
      setAdditionalPosts([]);
      setPostPage(1);
    }, [allUnfilteredPosts]);

    const fetchNextPosts = useFetchNextPosts();
    const handleLoadMore = async () => {
      if (selectedTopicFilter === 'critiqueQuestions') {
        const { posts: newPosts } = await fetchNextPosts();
        setAdditionalPosts((prev) => [...prev, ...newPosts]);
        return;
      }

      if (currentTab === 'feed') {
        setPostPage((prev) => prev + 1);
      }
    };

    const hasReachedEnd = useMemo(() => {
      if (currentTab === 'feed') {
        if (selectedTopicFilter === 'all') {
          return postsAreReady && posts?.length === allFilteredPosts?.length;
        }

        if (selectedTopicFilter === 'critiqueQuestions') {
          return false;
        }
      }

      return true;
    }, [currentTab, selectedTopicFilter, postsAreReady, posts, allFilteredPosts]);

    return (
      <Screen
        name="Community Screen"
        onReenter={refetchPosts}
        headerProps={{
          // left: <></>,
          // title: <Screen.GagglTitle />,
          left: (
            <div className="ps-2">
              <Screen.GagglTitle />
            </div>
          ),
          right: (
            <div className="d-flex gap-2">
              <ConversationsIcon />
              <IconButton
                color="neutral"
                variant="smooth"
                badge
                onClick={() => {
                  navigate('/notifications');
                }}
              >
                <FontAwesomeIcon name="bell" />
              </IconButton>
            </div>
          ),
        }}
      >
        <Screen.Content
          onPullToRefresh={refetchPosts}
          maxWidth={425}
          infiniteScroll={{
            hasReachedEnd,
            onLoadMore: handleLoadMore,
          }}
        >
          <TitleToolbar text={`Hey sis! 💃🏽`} className="title-toolbar-welcome" />
          {currentCity?.name ? (
            <TitleToolbar text={`Here's the ${currentCity?.name} tea... 😏`} size="md" />
          ) : (
            <div className="w-100 mb-2">
              <Skeleton height={26} />
            </div>
          )}
          <ButtonFilter
            className="mt-0"
            options={TAB_OPTIONS.map(({ value, label }) => ({
              value,
              label,
            }))}
            selected={currentTab}
            onChange={(updatedSearchType: TabOption) => {
              track(`Switch to ${TAB_OPTION_RECORD[updatedSearchType]}`);
              setCurrentTab(updatedSearchType);
            }}
          />
          {currentTab === 'feed' && (
            <>
              <TitleToolbar text="Trending 📈" size="md" />
              {currentCity?.slug === 'denver' && (
                <div className="d-grid gap-2 mb-3">
                  <div
                    className="d-flex align-items-center gap-2 p-2"
                    style={{ background: 'rgba(255,255,255,.1)', borderRadius: 8 }}
                    onClick={() => {
                      navigate('/noauth/series/black-drag-denver');
                    }}
                  >
                    <div>
                      <img
                        className="d-block"
                        src={inclusiveResistImage}
                        alt="Inclusive Resist"
                        style={{ height: 36, width: 36, objectFit: 'contain' }}
                      />
                    </div>
                    <div style={{ fontSize: '1.1rem' }}>
                      <strong>Featured BIPOC Artists</strong>
                    </div>
                    <div className="ml-auto px-2">
                      <FontAwesomeIcon name="chevron-right" />
                    </div>
                  </div>

                  <div
                    className="d-flex align-items-center gap-2 p-2"
                    style={{ background: 'rgba(255,255,255,.1)', borderRadius: 8 }}
                    onClick={() => {
                      navigate('/noauth/series/denver-drag-kings-and-things');
                    }}
                  >
                    <div>
                      <FeaturedKing />
                    </div>
                    <div style={{ fontSize: '1.1rem' }}>
                      <strong>Featured Kings/Things</strong>
                    </div>
                    <div className="ml-auto px-2">
                      <FontAwesomeIcon name="chevron-right" />
                    </div>
                  </div>
                </div>
              )}

              {TOPICS_ENABLED && (
                <>
                  {/* <TitleToolbar text="Popular Topics" size="md" /> */}
                  <HorizontalScroll
                    items={[
                      <ChipRadioOptions
                        options={Object.entries(TRENDING_TOPICS).map(([value, { label }]) => ({
                          value,
                          label,
                        }))}
                        value={selectedTopicFilter}
                        onChange={(value) => {
                          track('Change Trending Topic Filter', { value });
                          setPostPage(1);
                          setSelectedTopicFilter(value);
                        }}
                      />,
                    ]}
                  />
                </>
              )}
              {postsAreReady ? (
                <>
                  {posts?.length ? (
                    posts?.map((post, i) => (
                      <PostCard
                        key={post._id ?? i}
                        post={post}
                        onContentClick={handleCardClick(post)}
                        onCommentClick={handleCardClick(post)}
                      />
                    ))
                  ) : (
                    <EmptyState
                      title="Nothing trending"
                      text={`Gaggl is just getting started in ${currentCity?.name}. Check back later to see the more activity.`}
                    />
                  )}
                </>
              ) : (
                <div className="grid gap-2">
                  <Skeleton height={240} />
                  <Skeleton height={240} />
                  <Skeleton height={240} />
                </div>
              )}
            </>
          )}
          {currentTab === 'venues' && currentCity?._id && (
            <VenueRankingsContent city={currentCity} back="/community/venues" />
          )}
          {currentTab === 'promoters' && currentCity?._id && (
            <PromoterRankingsContent cityId={currentCity._id} />
          )}
        </Screen.Content>
        {/* {currentTab === 'feed' && (
        <Fab
          position="right-bottom"
          onClick={() => {
            track('Click New Post FAB');
            setCreateNewPostPopupIsVisible(true);
          }}
        >
          <FontAwesomeIcon name="plus" />
        </Fab>
      )} */}
        <Popup
          isVisible={createNewPostPopupIsVisible}
          onClose={() => setCreateNewPostPopupIsVisible(false)}
        >
          <Popup.Content>
            <TitleToolbar text="Spill the Tea... 🍵" size="md" />
            <Textarea
              onChange={setNewPostText}
              submitButton={{
                text: 'Spill!',
                disabled: isCreatingNewPost,
                onClick: handleCreateNewPost,
              }}
            />
          </Popup.Content>
        </Popup>
        <PostPopup
          isVisible={!!currentPost}
          post={currentPost}
          onClose={() => setCurrentPost(undefined)}
        />
      </Screen>
    );
  },
);
