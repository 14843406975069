import { List, ListItem, Toggle } from 'framework7-react';
import { ComponentProps, FC, useState } from 'react';
import { Spinner } from 'react-bootstrap';

import { useAppContext } from '@contexts/AppContext';
import { isEmpty } from 'lodash';
import { CitiesPopoverWrapper } from 'src/layouts/LocationPermissionGate/LocationPermissionGate';

import { useCurrentUser } from '@queries/UserQueries';

import {
  UpdateProfilePayload,
  useDeleteProfileMutation,
  useUpdateProfileMutation,
} from '@mutations/UserMutations';

import { useCurrentUserCity } from '@hooks/useCurrentUserLocation';
import { useOneSignal } from '@hooks/useOneSignal';

import { useRouter } from '@router/index';

import Button from '@components/buttons/Button';
import IconButton from '@components/buttons/IconButton';
import { Input } from '@components/form/Input';
import { PhoneInput } from '@components/form/PhoneInput';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import { Popup } from '@components/layout/Popup/Popup';
import { Screen } from '@components/layout/Screen/Screen';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import { CitiesField } from './entertainer/components/CitiesField';

export const ChangeCityButton: FC<{ buttonProps?: Partial<ComponentProps<typeof Button>> }> = ({
  buttonProps,
}) => {
  const { currentCityOverride } = useAppContext();
  const { currentCityIsReady, currentCity } = useCurrentUserCity();

  if (!currentCityIsReady) {
    return <></>;
  }

  return (
    <CitiesPopoverWrapper>
      <Button
        variant="outlined"
        color="neutral"
        roundness="rounded"
        endIcon={<FontAwesomeIcon name="chevron-down" />}
        {...buttonProps}
      >
        {currentCityOverride?.name ?? currentCity?.name}
      </Button>
    </CitiesPopoverWrapper>
  );
};

export const SettingsScreen: FC<{}> = () => {
  const { navigate } = useRouter();
  const { currentUserIsReady, currentUser, refetchUser } = useCurrentUser();
  const [deleteAccountOverlayIsVisible, setDeleteAccountOverlayIsVisible] =
    useState<boolean>(false);
  const { deleteProfileAsync } = useDeleteProfileMutation();
  const { isSubmitting, updateProfileAsync } = useUpdateProfileMutation();
  const { updateToken } = useAppContext();
  const { pushNotificationsEnabled } = useOneSignal();

  const handleNotificationToggleChange = async (isEnabled: boolean) => {
    await updateProfileAsync({
      notificationsStatus: isEnabled ? 'enabled' : 'disabled',
    });
  };

  const handleSmsNotificationToggleChange = async (isEnabled: boolean) => {
    await updateProfileAsync({
      smsNotificationsStatus: isEnabled ? 'enabled' : 'disabled',
    });
  };

  const handleDeleteAccount = async () => {
    await deleteProfileAsync();
    updateToken();
    navigate('/login', {
      animate: false,
    });
  };

  const settings = [
    {
      title: 'Notifications',
      icon: 'bell',
      toggle: (
        <Toggle
          slot="after"
          defaultChecked={currentUser.notificationsEnabled && pushNotificationsEnabled}
          disabled={isSubmitting}
          onToggleChange={handleNotificationToggleChange}
        />
      ),
    },
    {
      title: 'SMS Notifications',
      icon: 'sms',
      toggle: (
        <Toggle
          slot="after"
          defaultChecked={currentUser.smsNotificationsEnabled}
          disabled={isSubmitting}
          onToggleChange={handleSmsNotificationToggleChange}
        />
      ),
    },
  ];

  return (
    <Screen
      name="Settings Screen"
      headerProps={{
        back: { to: '/profile' },
        title: 'Settings',
        right: (
          <IconButton
            variant="flat"
            color="danger"
            onClick={() => setDeleteAccountOverlayIsVisible(true)}
          >
            <FontAwesomeIcon faStyle="far" name="trash-alt" />
          </IconButton>
        ),
      }}
    >
      <Screen.Content>
        <List className="mt-0">
          <ListItem title="Notifications">
            <div slot="media">
              <div style={{ width: 18 }}>
                <FontAwesomeIcon name="bell" />
              </div>
            </div>
            <Toggle
              slot="after"
              defaultChecked={currentUser.notificationsEnabled && pushNotificationsEnabled}
              disabled={isSubmitting}
              onToggleChange={handleNotificationToggleChange}
            />
          </ListItem>
          <ListItem title="SMS Notifications">
            <div slot="media">
              <div style={{ width: 18 }}>
                <FontAwesomeIcon name="sms" />
              </div>
            </div>
            <Toggle
              slot="after"
              defaultChecked={currentUser.smsNotificationsEnabled}
              disabled={isSubmitting}
              onToggleChange={handleSmsNotificationToggleChange}
            />
          </ListItem>
          <ListItem title="City">
            <div slot="media">
              <div style={{ width: 18 }}>
                <FontAwesomeIcon name="map-marker-alt" />
              </div>
            </div>
            <div slot="after">
              <ChangeCityButton />
            </div>
          </ListItem>
        </List>
      </Screen.Content>
      <Popup
        isVisible={deleteAccountOverlayIsVisible}
        onClose={() => setDeleteAccountOverlayIsVisible(false)}
      >
        <Popup.Content>
          <TitleToolbar text="Are you sure you want to delete your account?" size="md" />
          <div className="d-flex gap-2 w-100">
            <div className="w-50">
              <Button
                noWrap
                roundness="rounded"
                color="danger"
                variant="outlined"
                onClick={handleDeleteAccount}
              >
                Delete Account
              </Button>
            </div>
            <div className="w-50">
              <Button
                noWrap
                roundness="rounded"
                color="neutral"
                onClick={() => setDeleteAccountOverlayIsVisible(false)}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Popup.Content>
      </Popup>
    </Screen>
  );
};
