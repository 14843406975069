import { ChangeEventHandler, FC, useEffect, useMemo, useRef, useState } from 'react';

import { Camera, CameraResultType } from '@capacitor/camera';
import * as Sentry from '@sentry/browser';

import { useDevice } from '@hooks/useDevice';

import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';

const IMAGE_FORMATS = ['x-png', 'jpeg', 'gif', 'png', 'jpg', 'webp', 'heic'];

interface ImageUploadFieldProps {
  placeholder: string;
  defaultValue?: string;
  onChange: (blob: string | ArrayBuffer) => void;
}

const NATIVE_IMAGE_ENABLED = false;

const adjustImageOrientation = async (file: File) => {
  return new Promise<Blob>((resolve) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // Set canvas dimensions to match the image dimensions
      canvas.width = img.width;
      canvas.height = img.height;

      // Draw the image on the canvas at full size
      ctx.drawImage(img, 0, 0, img.width, img.height);
      canvas.toBlob(resolve, 'image/jpeg');
    };
    img.onerror = (err) => {
      Sentry.captureException(err);
      resolve(file);
    };
    img.src = URL.createObjectURL(file);
  });
};

const readFile = (file: Blob): Promise<string | ArrayBuffer> => {
  return new Promise<string | ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = (error) => {
      Sentry.captureException(error);
      console.log('Error: ', error);
      reject(error);
    };
  });
};

export const ImageUploadField: FC<ImageUploadFieldProps> = ({
  placeholder,
  defaultValue,
  onChange,
}) => {
  const { isNativePlatform } = useDevice();
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<Blob | MediaSource>();
  const [previewUrl, setPreviewUrl] = useState<string>(defaultValue);

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreviewUrl(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const handleClick = async () => {
    if (isNativePlatform() && NATIVE_IMAGE_ENABLED) {
      const image = await Camera.getPhoto({
        quality: 90,
        allowEditing: true,
        resultType: CameraResultType.Base64,
      });

      const blob = new Blob([image.base64String], { type: 'image/jpeg' });
      setSelectedFile(blob);
      onChange(image.base64String);
    } else {
      inputRef.current?.click();
    }
  };

  const handleChangeFile: ChangeEventHandler<HTMLInputElement> = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    const firstFile = e.target.files[0];

    setSelectedFile(firstFile);

    try {
      const orientedImageBlob = await adjustImageOrientation(firstFile);
      const blob = await readFile(orientedImageBlob);
      onChange(blob);
    } catch (err) {
      console.log('Error reading oriented file, trying original file');
      try {
        const blob = await readFile(firstFile);
        onChange(blob);
      } catch (nextError) {
        console.log('Error reading original file');
      }
    }
  };

  return (
    <div onClick={handleClick} style={{ minHeight: 32 }}>
      {previewUrl ? (
        <img className="d-block" src={previewUrl} />
      ) : (
        <div className="d-flex flex-column align-items-center py-5 px-2">
          <div className="fs-1">
            <FontAwesomeIcon name="image" />
          </div>
          <span>{placeholder}</span>
        </div>
      )}
      <div className="d-none">
        <input
          ref={inputRef}
          type="file"
          accept={IMAGE_FORMATS.map((ext) => `image/${ext}`).join(',')}
          onChange={handleChangeFile}
        />
      </div>
    </div>
  );
};
