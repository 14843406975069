export const FeaturedKing = ({ height = 36, width = 36 }) => (
  <svg
    height={height}
    viewBox="0 0 48 57"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    id="fi_5014877"
    style={{ display: 'block' }}
  >
    <g id="Page-1" fill="none" fill-rule="evenodd">
      <g id="009---King">
        <path id="Rectangle" d="m6 25h35v8h-35z" fill="#f29c1f"></path>
        <rect id="Rectangle" fill="#e57e25" height="4" rx="2" width="40" x="4" y="22"></rect>
        <rect id="Rectangle" fill="#e57e25" height="4" rx="2" width="39" x="4" y="32"></rect>
        <path
          id="Path"
          d="m41 22c2-2 2-10 2-15l-6.165 3.523c-.4661007.2663513-1.0593499.1171562-1.344-.338l-4.491-7.185-6.349 5.442c-.374224.32008772-.925776.32008772-1.3 0l-6.351-5.442-4.491 7.185c-.2846501.4551562-.8778993.6043513-1.344.338l-6.165-3.523c0 5 0 13 2 15"
          fill="#f0c419"
        ></path>
        <g fill="#955ba5">
          <circle id="Oval" cx="17" cy="2" r="2"></circle>
          <circle id="Oval" cx="31" cy="2" r="2"></circle>
          <circle id="Oval" cx="43" cy="6" r="2"></circle>
          <circle id="Oval" cx="5" cy="6" r="2"></circle>
        </g>
        <path id="Rectangle" d="m7 26h34v6h-34z" fill="#f29c1f"></path>
        <path
          id="Path"
          d="m24 50s3 7 13 7c7.162 0 9.707-4.616 10.575-7.237.1094927-.3413263.0267274-.7151669-.2166054-.9783805-.2433328-.2632137-.6095368-.375024-.9583946-.2926195-6.562 1.537-7.754-4.704-12.4-7.492-5-3-10 2-10 4 0-2-5-7-10-4-4.646 2.788-5.838 9.029-12.4 7.492-.34841269-.0810674-.71351358.03133-.95601015.2943105-.24249656.2629805-.32498323.6359788-.21598985.9766895.865 2.621 3.41 7.237 10.572 7.237 10 0 13-7 13-7z"
          fill="#a56a43"
        ></path>
        <ellipse id="Oval" cx="19" cy="15" fill="#955ba5" rx="2" ry="3"></ellipse>
        <ellipse id="Oval" cx="29" cy="15" fill="#955ba5" rx="2" ry="3"></ellipse>
        <circle id="Oval" cx="37" cy="16" fill="#955ba5" r="1"></circle>
        <circle id="Oval" cx="11" cy="16" fill="#955ba5" r="1"></circle>
      </g>
    </g>
  </svg>
);
