import { ReactElement } from 'react';

import { isEmpty } from 'lodash';
import { CHALLENGES_ENABLED } from 'src/constants/FeatureFlags';
import { EntertainerToolbarIcon } from 'src/layouts/EntertainerToolbarIcon';

import { useCurrentEntertainerQuery } from '@queries/EntertainerQueries';

import { useLocation } from '@router/index';

import { isEnv } from '@utilities/isEnv';

import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import { Framework7Icon } from '@components/icons/Framework7Icon';
import { LineAwesomeIcon } from '@components/icons/LineAwesomeIcon';
import { TriviaIcon } from '@components/icons/TriviaIcon';

import { useCurrentPromoterQuery } from '../queries/PromoterQueries';

const ENABLE_TRIVIA = false;

interface TabbarRoute {
  path: string;
  icon: ReactElement;
  title: string;
  isVisible?: boolean;
  requiresEventsEnabled?: boolean;
}

export const deprecatedRoutes: TabbarRoute[] = [
  {
    path: '/trivia',
    icon: <TriviaIcon />,
    title: 'Trivia',
    isVisible: ENABLE_TRIVIA,
  },
  {
    path: '/events',
    icon: <LineAwesomeIcon name="calendar" />,
    title: 'Events',
    // requiresEventsEnabled: true,
  },
  { path: '/venues', icon: <LineAwesomeIcon name="beer" />, title: 'Bars' },
  { path: '/profile', icon: <LineAwesomeIcon name="user-circle" />, title: 'Profile' },
];

export const useTabbarRoutes = (): TabbarRoute[] => {
  const { promoter } = useCurrentPromoterQuery();
  const { entertainer } = useCurrentEntertainerQuery();

  return [
    {
      path: '/home',
      icon: <FontAwesomeIcon name="home" />,
      title: 'Home',
    },
    ...(CHALLENGES_ENABLED && !!entertainer?.title
      ? [
          {
            path: '/challenges',
            icon: <LineAwesomeIcon name="trophy" />,
            title: 'Challenges',
          },
        ]
      : []),
    {
      path: '/discover',
      icon: <Framework7Icon name="sparkles" />,
      title: 'Discover',
    },
    // { path: '/community', icon: <FontAwesomeIcon name="mug-hot" />, title: 'Tea' },
    {
      isVisible: !!entertainer?.title,
      path: '/manage/entertainer',
      icon: <EntertainerToolbarIcon height={28} width={28} />,
      title: 'Entertainer',
    },
    // {
    //   path: '/notifications',
    //   icon: <FontAwesomeIcon name="bell" />,
    //   title: 'Notifications',
    // },
    // {
    //   isVisible: !!promoter,
    //   path: '/manage/promoter',
    //   icon: <FontAwesomeIcon name="bullhorn" />,
    //   title: 'Promote',
    // },
    { path: '/profile', icon: <LineAwesomeIcon name="user-circle" />, title: 'Profile' },
  ];
};

export const useTabbarIsVisible = () => {
  const location = useLocation();
  const routes = useTabbarRoutes();
  return { tabbarIsVisible: routes.map(({ path }) => path).includes(location.pathname) };
};
