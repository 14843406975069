import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';

import dayjs from 'dayjs';

import API from '@api/API';

import { useAppContext } from '../contexts/AppContext';
import { FeedEvent } from './EventQueries';
import { Promoter } from './PromoterQueries';

export interface CritiqueData {
  basicRatings: {
    like: number;
    neutral: number;
    dislike: number;
  };
  reasons: {
    like: { count: number; label: string }[];
    neutral: { count: number; label: string }[];
    dislike: { count: number; label: string }[];
  };
  reasonTotals: {
    like: number;
    neutral: number;
    dislike: number;
  };
}

export interface UserCritique {
  primaryCritique: BasicRating;
  canUserSendEntertainerBadge: boolean;
  userSendBadgeResetTime?: string;
  like: string[];
  neutral: string[];
  dislike: string[];
}

const BASIC_RATINGS = ['like', 'dislike', 'neutral'];
export type BasicRating = (typeof BASIC_RATINGS)[number];

export interface SuggestedCritiqueResponseBody {
  events: FeedEvent[];
  venues: any[];
  promoters: Promoter[];
}
export const useSuggestedCritiquesQuery = () => {
  const { userIsLoggedIn } = useAppContext();

  const { isFetched, data, refetch } = useQuery({
    queryKey: useSuggestedCritiquesQuery.queryKey(),
    queryFn: () => useSuggestedCritiquesQuery.queryFn(),
    enabled: userIsLoggedIn,
  });

  return {
    suggestedCritiquesAreReady: isFetched,
    suggestedCritiques: data,
    refetchCritiques: refetch,
  };
};
useSuggestedCritiquesQuery.queryKey = () => ['suggested-critiques'];
useSuggestedCritiquesQuery.queryFn = () =>
  API.get<SuggestedCritiqueResponseBody>(`/v1/critiques/suggested`);

export interface UserGagglRankResponseBody {
  rank: number;
  score: number;
}
export const useCurrentUserGagglRankQuery = () => {
  const { userIsLoggedIn } = useAppContext();

  const { isFetched, data, refetch } = useQuery({
    queryKey: useCurrentUserGagglRankQuery.queryKey(),
    queryFn: () => useCurrentUserGagglRankQuery.queryFn(),
    enabled: userIsLoggedIn,
  });

  return {
    currentUserGagglRankAreReady: isFetched,
    currentUserGagglRank: data,
    refetchCurrentUserGagglRank: refetch,
  };
};
useCurrentUserGagglRankQuery.queryKey = () => ['currentUser', 'rank'];
useCurrentUserGagglRankQuery.queryFn = () => API.get<UserGagglRankResponseBody>(`/v1/user/rank`);

export const fetchCurrentUserGagglRank = async (queryClient: QueryClient) => {
  await queryClient.invalidateQueries({ queryKey: useCurrentUserGagglRankQuery.queryKey() });

  return queryClient.fetchQuery({
    queryKey: useCurrentUserGagglRankQuery.queryKey(),
    queryFn: useCurrentUserGagglRankQuery.queryFn,
  });
};

export const useFetchCurrentUserGagglRank = () => {
  const queryClient = useQueryClient();

  return () => fetchCurrentUserGagglRank(queryClient);
};

export interface CritiqueQuestion {
  _id: string;
  question: string;
  answer?: string; // Sometimes we send the answer so that the user can see what they answered
  options?: { value: string; label: string }[];
}
export interface QuickPicksResponseBody {
  critiqueQuestions: CritiqueQuestion[];
}
export const useCritiqueQuestionsQuery = () => {
  const { userIsLoggedIn } = useAppContext();

  const { isFetched, data, refetch } = useQuery({
    queryKey: useCritiqueQuestionsQuery.queryKey(),
    queryFn: () => useCritiqueQuestionsQuery.queryFn(),
    enabled: userIsLoggedIn,
  });

  return {
    critiqueQuestionsAreReady: isFetched,
    critiqueQuestions: data?.critiqueQuestions,
    refetchCritiqueQuestions: refetch,
  };
};
useCritiqueQuestionsQuery.queryKey = () => ['critique-questions'];
useCritiqueQuestionsQuery.queryFn = () =>
  API.get<QuickPicksResponseBody>(`/v1/critiques/quick-picks`);

export interface RankingsResponseBody {
  rankings: { rank: number; displayName: string; score: number }[];
}
export const useRankingsQuery = () => {
  const { userIsLoggedIn } = useAppContext();

  const { isFetched, data, refetch } = useQuery({
    queryKey: useRankingsQuery.queryKey(),
    queryFn: () => useRankingsQuery.queryFn(),
    enabled: userIsLoggedIn,
  });

  return {
    rankingsAreReady: isFetched,
    rankings: data?.rankings,
    refetchRankings: refetch,
  };
};
useRankingsQuery.queryKey = () => ['rankings'];
useRankingsQuery.queryFn = () => API.get<RankingsResponseBody>(`/v1/rankings`);

interface DocumentRanking {
  _id: string;
  title: string;
  rank: number;
  primaryRating: string;
}
export interface VenueAndPromoterRankingsResponseBody {
  venues: DocumentRanking[];
  promoters: DocumentRanking[];
}
export const useVenueAndPromoterRankings = (cityId: string) => {
  const { userIsLoggedIn } = useAppContext();

  const { isFetched, data, refetch } = useQuery({
    queryKey: useVenueAndPromoterRankings.queryKey(cityId, userIsLoggedIn),
    queryFn: () => useVenueAndPromoterRankings.queryFn(cityId),
  });

  return {
    venueAndPromoterRankingsAreReady: isFetched,
    venueRankings: data?.venues,
    promoterRankings: data?.promoters,
    refetchVenueAndPromoterRankings: refetch,
  };
};
useVenueAndPromoterRankings.queryKey = (cityId: string, userIsLoggedIn: boolean) => [
  'top-venues-promoters',
  cityId,
  userIsLoggedIn,
];
useVenueAndPromoterRankings.queryFn = (cityId: string) =>
  API.get<VenueAndPromoterRankingsResponseBody>(`/v1/cities/${cityId}/top`);

export interface RecommendationQuestionsResponseBody {
  questions: Record<string, { pluralLabel: string; question: string; reasonLabel: string }>;
  recommendAndEarn?: {
    label: string;
    dialog: {
      title: string;
      content: string;
    };
  };
}

export const useRecommendationQuestions = () => {
  const { userIsLoggedIn } = useAppContext();

  const { isFetched, data, refetch } = useQuery({
    queryKey: useRecommendationQuestions.queryKey(),
    queryFn: () => useRecommendationQuestions.queryFn(),
    enabled: userIsLoggedIn,
  });

  return {
    recommendationQuestionsAreReady: isFetched,
    recommendationQuestions: data?.questions ?? {},
    recommendAndEarn: data?.recommendAndEarn,
    refetchRecommendationQuestions: refetch,
  };
};

useRecommendationQuestions.queryKey = () => [
  'recommendation-questions',
  dayjs().format('YYYY-MM-DD'),
];
useRecommendationQuestions.queryFn = () =>
  API.get<RecommendationQuestionsResponseBody>(`/v1/recommendation-questions`);
