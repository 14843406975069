export const sanitizeVenmoUsername = (originalUsername: string) => {
  let username = originalUsername.trim(); // Remove extra spaces

  // Extract username from URL if it starts with 'http'
  if (username.startsWith('http')) {
    username = username.split('/').pop() || '';
  }

  return username
    ?.replace(/^@/, '') // Remove leading '@'
    .replace(/[^a-zA-Z0-9._-]/g, ''); // Keep only allowed Venmo characters
};
