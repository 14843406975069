import { useQuery } from '@tanstack/react-query';

import API from '@api/API';

import { FeedEvent } from './EventQueries';

export interface FeedPost {
  _id: string;
  isAnonymousReview?: boolean;
  text: string;
  createdAt: string;
  hideActions?: boolean;
  disableClick?: boolean;
  user?: {
    _id: string;
    displayName: string;
  };
  postHeader?: {
    sectionTitle: string;
    title: string;
    action?: {
      type: 'entertainerPopup' | 'venuePopup' | 'promoterPopup' | 'eventPopup';
      document: { _id: string; title: string };
    };
  };
  reviewedVenue?: {
    _id: string;
    title: string;
  };
  event?: FeedEvent;
  comments: FeedPost[];
  meta?: {
    to: string;
    buttonText: string;
  };
}

// Not yet...
export interface PostV2 {
  postId?: string;
  postHeader?: {
    sectionTitle: string;
    title: string;
    createdAt: string;
    action?: {
      type: 'entertainerPopup' | 'venuePopup' | 'promoterPopup' | 'eventPopup';
    };
  };
  image?: string;
  action:
    | {
        type: 'primaryRatingActivity';
        documentType: 'venue' | 'entertainer' | 'promoter';
        documentId: string;
      }
    | { type: 'yesNoCritiqueQuestion'; critiqueQuestionId: string; documentId: string }
    | { type: 'agreeDisagree' | 'emojiReactions'; postId: string }
    | { type: 'eventInterest'; eventId: string };
  footerButtons: {
    text: string;
    action: 'eventCritique' | 'answerCritiqueQuestion' | 'postReaction';
    value: '';
  }[];
  comments: FeedPost[];
}

export const useFeedPostsQuery = (
  isEnabled = true,
  options?: {
    refetchOnMount?: 'always';
    refetchOnReconnect?: 'always';
    refetchOnWindowFocus?: 'always';
  },
) => {
  const {
    refetchOnMount = 'always',
    refetchOnReconnect = 'always',
    refetchOnWindowFocus = 'always',
  } = options ?? {};
  const { isFetched, data, refetch } = useQuery({
    queryKey: useFeedPostsQuery.queryKey(),
    queryFn: () => API.get<{ posts: FeedPost[] }>(`/v1/user/feed/posts?v=v2`),
    enabled: isEnabled,
    refetchOnMount,
    refetchOnReconnect,
    refetchOnWindowFocus,
  });

  return {
    postsAreReady: isFetched,
    posts: data?.posts,
    refetchPosts: refetch,
  };
};
useFeedPostsQuery.queryKey = () => ['feed', 'posts'];

export const useFetchNextPosts = () => {
  return (collection?: 'entertainers' | 'venues' | 'promoters', cityId?: string) => {
    const queryParams = new URLSearchParams();

    if (collection) {
      queryParams.append('collection', collection);
    }

    if (cityId) {
      queryParams.append('cityId', cityId);
    }

    const queryString = queryParams.toString();

    return API.get<{ posts: FeedPost[] }>(`/v1/user/feed/next-posts?${queryString}`);
  };
};

export const useEntertainerFeedPostsQuery = (
  params: {
    cityId?: string;
  },
  isEnabled = true,
  options?: {
    refetchOnMount?: 'always';
    refetchOnReconnect?: 'always';
    refetchOnWindowFocus?: 'always';
  },
) => {
  const {
    refetchOnMount = 'always',
    refetchOnReconnect = 'always',
    refetchOnWindowFocus = 'always',
  } = options ?? {};
  const { isFetched, data, refetch } = useQuery({
    queryKey: useEntertainerFeedPostsQuery.queryKey(params),
    queryFn: () =>
      API.get<{ posts: FeedPost[] }>(`/v1/user/feed/entertainer-posts?cityId=${params.cityId}`),
    enabled: isEnabled && !!params?.cityId,
    refetchOnMount,
    refetchOnReconnect,
    refetchOnWindowFocus,
  });

  return {
    postsAreReady: isFetched,
    posts: data?.posts,
    refetchPosts: refetch,
  };
};
useEntertainerFeedPostsQuery.queryKey = (params: { cityId?: string }) => [
  'entertainer-feed',
  'posts',
  params,
];

export const useFeaturedLooksFeedPostsQuery = (
  params: {
    cityId?: string;
  },
  isEnabled = true,
  options?: {
    refetchOnMount?: 'always';
    refetchOnReconnect?: 'always';
    refetchOnWindowFocus?: 'always';
  },
) => {
  const {
    refetchOnMount = 'always',
    refetchOnReconnect = 'always',
    refetchOnWindowFocus = 'always',
  } = options ?? {};
  const { isFetched, data, refetch } = useQuery({
    queryKey: useFeaturedLooksFeedPostsQuery.queryKey(params),
    queryFn: () => useFeaturedLooksFeedPostsQuery.queryFn(params),

    enabled: isEnabled && !!params?.cityId,
    refetchOnMount,
    refetchOnReconnect,
    refetchOnWindowFocus,
  });

  return {
    postsAreReady: isFetched,
    posts: data?.posts,
    refetchPosts: refetch,
  };
};
useFeaturedLooksFeedPostsQuery.queryKey = (params: { cityId?: string }) => [
  'featured-looks-feed',
  'posts',
  params,
];
useFeaturedLooksFeedPostsQuery.queryFn = (params: { cityId?: string }) =>
  API.get<{ posts: FeedPost[] }>(`/v1/user/feed/featured-looks-posts?cityId=${params.cityId}`);

export interface InAppMessageQuery {
  _id: string;
  createdAt: string;
  message: string;
  response: {
    requestType: string;
    shortLink: string;
    isSudoLang: boolean;
    associatedData: {
      venueIds: string[];
      eventIds: string[];
      venues: { title: string; tagline: string; description: string }[];
      events: any[];
    };
    message: string;
  };
}

export const useInAppMessageQuery = (queryId?: string, isEnabled = true) => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: ['inquiry', queryId],
    queryFn: () => API.get<{ query: InAppMessageQuery }>(`/v1/user/inquiry/${queryId}`),
    enabled: queryId && isEnabled,
  });

  return {
    queryIsReady: isFetched,
    query: data?.query,
    refetchQuery: refetch,
  };
};

export interface PostReactionResponse {
  emojiReactionCounts: Record<string, number>;
  currentUserEmojiReaction: string;
}

export const usePostReactionQuery = (postId: string | undefined, isEnabled: boolean) => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: usePostReactionQuery?.queryKey(postId),
    queryFn: () => API.get<PostReactionResponse>(`/v1/posts/${postId}/reactions`),
    enabled: !!postId && isEnabled,
  });

  return {
    postReactionsAreReady: isFetched,
    postReactions: data,
    refetchPostReactions: refetch,
  };
};
usePostReactionQuery.queryKey = (postId?: string) => ['post-reactions', postId];

export const useEventCommentsQuery = (eventId: string, isEnabled = true) => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: useEventCommentsQuery.queryKey(eventId),
    queryFn: () => useEventCommentsQuery.queryFn(eventId),
    enabled: eventId && isEnabled,
  });

  return {
    eventCommentsAreReady: isFetched,
    eventComments: data?.comments,
    refetchEventComments: refetch,
  };
};
useEventCommentsQuery.queryKey = (eventId: string) => ['event-comments', eventId];
useEventCommentsQuery.queryFn = (eventId: string) =>
  API.get<{ comments: FeedPost[] }>(`/v1/events/${eventId}/comments`);

export const useVenuePostsQuery = (venueId: string, isEnabled = true) => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: useVenuePostsQuery.queryKey(venueId),
    queryFn: () => useVenuePostsQuery.queryFn(venueId),
    enabled: venueId && isEnabled,
  });

  return {
    venuePostsAreReady: isFetched,
    venuePosts: data?.posts,
    refetchVenuePosts: refetch,
  };
};
useVenuePostsQuery.queryKey = (venueId: string) => ['venue-posts', venueId];
useVenuePostsQuery.queryFn = (venueId: string) =>
  API.get<{ posts: FeedPost[] }>(`/v1/venues/${venueId}/posts`);

export const useVenueEventsQuery = (venueId: string, isEnabled = true) => {
  const { isFetched, data, refetch } = useQuery({
    queryKey: useVenueEventsQuery.queryKey(venueId),
    queryFn: () => useVenueEventsQuery.queryFn(venueId),
    enabled: venueId && isEnabled,
  });

  return {
    venueEventsAreReady: isFetched,
    venueEvents: data,
    refetchVenueEvents: refetch,
  };
};
useVenueEventsQuery.queryKey = (venueId: string) => ['venue-events', venueId];
useVenueEventsQuery.queryFn = (venueId: string) =>
  API.get<{ upcoming: FeedEvent[]; previous: FeedEvent[] }>(`/v1/venues/${venueId}/events`);
